<template lang="pug">
  .ServiceMoreCommon
    .ServiceMoreCommon-h1 {{ service.name }}
    .ServiceMoreCommon-important(v-if="service.info.important_information") {{ service.info.important_information }}
    .ServiceMoreCommon-description(
      v-if="service.info.detailed_description"
      v-html="service.info.detailed_description"
    )
    .ServiceMoreCommon-description(v-if="service.info.short_description && !service.info.detailed_description")
      | {{ service.info.short_description }}

    .ServiceMore-options.g-hide-desktop(v-if="service.id")
      ServiceDetails(
        :clear-background="true"
        :info="service.info"
        :map="map"
        :no-price-block="true"
        :price="service.price"
        :service="service"
        step-info="3"
        location="hide"
      )

    .ServiceMoreCommon-options
      div(v-for="desc in particular")
        .ServiceMoreCommon-h2 {{ desc.name || desc.type }}
        div {{ desc.full_description || desc.description_detailed }}

    .ServiceMoreCommon-special(
      v-if="service.info.free_of_charge_categories_description")
      .ServiceMoreCommon-h2 Особые условия обслуживания
      .ServiceMoreCommon-special-text
        p {{ service.info.free_of_charge_categories_description }}

    .ServiceMoreCommon-price#price
      .ServiceMoreCommon-price.ServiceMoreCommon-h2 Стоимость
      .ServiceMoreCommon-price--table(v-if="service.id")
        .Services-infoBlock-price-count
          .Services-infoBlock-price-info(v-if="service.price") Предварительная стоимость&nbsp;для одного пассажира
          .Services-infoBlock-price-info(v-else) Стоимость мы сообщим вам после обработки заказа&nbsp;
          .Services-infoBlock-price-value(v-if="service.price") {{ (service.price).toLocaleString('ru') }} ₽

        .Services-infoBlock-price-text(v-if="service.info.tariffs_for_children && service.price")
          | {{ service.info.tariffs_for_children }}

      .Services-infoBlock-price-text(v-if="service.info.special_conditions") {{ service.info.special_conditions }}

    .ServiceMoreCommon-penalties
      .ServiceMoreCommon-penalties.ServiceMoreCommon-h2 Условия изменения и аннуляции
      .ServiceMoreCommon-penalties--text(v-if="isUrgentInfoHidden")
        p Отсутствуют

      .ServiceMoreCommon-penalties--text(
        v-html="penalty"
        v-else
      )

</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import ServiceDetails from '@/components/ServiceDetails'
import mapOptionsIcons from '@/utils/mapOptionsIcons'
// import getDefaultCancellationDescription from '@/utils/getDefaultCancellationDescription'

export default {
  name: 'ServiceMoreCommon',
  props: ['service'],
  components: {
    ServiceDetails,
  },
  data () {
    const map = mapOptionsIcons
    return {
      map,
      data: {
        info: {}
      },
      price: ''
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      partner_id: (state) => state.partner_id
    }),
    penalty () {
      if (this.service.info) {
        return this.service.info.cancellation_description || this.service.penalties_description
      } else {
        return null
      }
    },
    particular () {
      const filtered = _.sortBy(this.service.info.option_categories, o => o.priority)
      const availabilityOptions = _.filter(filtered, o => _.filter(o.options, opt => opt.availability !== 2).length)
      const withOptions = _.filter(availabilityOptions, (o) => o.options.length)

      const withFullOptions = withOptions.filter(category => category.options.find(option => option.full_description))
      const withDetailedDesc = withOptions.filter(category => category.description_detailed && !category.options.find(option => option.full_description))
      const arr = []
      withFullOptions.map(c => {
        c.options = c.options.map(o => {
          if (o.full_description) arr.push(o)
          return o
        })
        return c
      })
      return arr.concat(withDetailedDesc)
    },
    isUrgentInfoHidden () {
      const isKRRPartner = this.partnerId === '84c639ff-b824-3515-ae57-9e8cd3785375' && this.service.terminal_id === 96
      const isAAQPartner = this.partnerId === '7e697689-41d8-34c1-8fc9-09c87a06c895' && this.service.terminal_id === 73
      const isAERPartner = this.partnerId === 'e201bbbb-5d84-35df-a072-fbf87a6bda14' && this.service.terminal_id === 124
      return isKRRPartner || isAAQPartner || isAERPartner
    }
  }
}
</script>

<style scoped lang="stylus">
@import '../assets/variables.styl'

  .ServiceMoreCommon
    padding: 10px 40px
    &-h1
      font-size: 24px

    &-h2
      font-size: 20px

    &-price
      &--header
        font-size: 20px

    &-important
      font-size: 18px
      border-left: solid 5px var(--main)
      padding-left: 10px
      margin-bottom: 30px

    &-description
      p
        margin-bottom: .6875rem

      *
        &:last-child
          margin-bottom: 0 !important

</style>
