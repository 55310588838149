<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M18.76,10.42l-1,1.31L9.98,5.91L2.29,11.8l-1.05-1.31l8.74-7.08l3.49,2.79l0.12-2.03l2.75,0.11l-0.07,4.17  L18.76,10.42z M3.82,11.97l6.23-4.72l6.34,4.63l-0.16,6.49l1.76-0.04l-0.09,2.12H2.35v-1.8l1.58-0.03L3.82,11.97z M5.98,15.73  l2.78-0.1v-3.06l-2.87-0.03L5.98,15.73z M13.66,13.51l-2.92-0.03l0.13,4.99l2.67-0.06L13.66,13.51z"/>
  </svg>
</template>
<script>
export default {
  name: 'IconServiceDomestic'
}
</script>
<style scoped>
  .st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
