import theme from './colors-helper.js'

const colors = (percentage, from, to) => {
  if (percentage < -1 || percentage > 1) {
    return console.log('Invalid Percentage Range')
  }
  return theme(percentage, from, to)
}

export default {
  install: (Vue) => {
    Vue.prototype.$colors = colors
  }
}
