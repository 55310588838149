<template>
  <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 93.2 67.5" style="enable-background:new 0 0 93.2 67.5;" xml:space="preserve">
    <path class="st0" d="M6.1,67.5c-2.8,0-5,0-5.1-5c-0.3-10-0.8-31.9-0.9-37c-0.2-8,3.8-9.9,10.8-10.9c3.3-0.5,10.3-1.6,17.3-2.6l2.5-8.7c7.1-4.5,25.9-4,33.3-0.5l3.1,9.7c7.8,1.2,15.5,2.4,18.7,2.9c4.1,0.6,7.5,3.6,7.4,10.2c-0.1,5.2-0.3,26.5-0.5,36.3c-0.1,4.8-2.3,5-5.1,5L6.1,67.5z M14,22.1c-5.1,0-5.3,7.7,0.1,7.7C19.1,29.8,19.1,22.1,14,22.1z M47.5,19.7c-26.1,0-27.4,39.7,0.4,39.7C73.4,59.4,73.5,19.7,47.5,19.7z M6.6,6.5l12.3-0.1l1.1,4.7L5.6,11.7L6.6,6.5z"/>
    <path class="st1" d="M47.2,26.7c-17.7,0.1-17.6,25.8,0.1,25.7C65,52.4,64.9,26.6,47.2,26.7z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPhoto'
}
</script>
