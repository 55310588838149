<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <rect x="4.73" y="13.89" width="5.79" height="0.71"/>
    <rect x="5.74" y="15.55" width="3.77" height="0.71"/>
    <path d="M7.62,12.56c-0.94,0-1.7-0.76-1.7-1.7c0-0.94,0.76-1.7,1.7-1.7c0.94,0,1.7,0.76,1.7,1.7C9.33,11.8,8.56,12.56,7.62,12.56zM7.62,9.44c-0.78,0-1.42,0.63-1.42,1.42c0,0.78,0.63,1.42,1.42,1.42c0.78,0,1.42-0.63,1.42-1.42C9.04,10.08,8.4,9.44,7.62,9.44z"/>
    <path d="M3.06,20H2V6h8.48c1.84,0,2.77,0.9,2.77,2.66v8.85c0,1.64-0.87,2.47-2.6,2.47L3.06,20z M2.99,19.01L10.65,19c1.18,0,1.61-0.4,1.61-1.48V8.66c0-1.22-0.48-1.68-1.78-1.68H2.99V19.01z"/>
    <circle cx="7.62" cy="10.86" r="0.62"/>
    <path d="M18.36,5.97l-7.15-1.91c-0.48-0.13-0.98,0.16-1.11,0.64L9.66,6.37h1.07c1.51,0,2.23,0.61,2.35,1.88c0.43,0.11,0.83,0.22,0.98,0.26l1.82-1.7l0.51,0.14l-1.14,1.88c0.33,0.09,0.68,0.18,0.9,0.24l0.69-0.41l0.34,0.09l-0.54,0.81l0.06,0.97l-0.34-0.09l-0.4-0.7c-0.22-0.06-0.57-0.15-0.9-0.24l0.05,2.2l-0.51-0.14l-0.73-2.38c-0.08-0.02-0.41-0.11-0.78-0.21c0,1.05,0,4.12,0,6.35l0.81-2.95l0.58,0.16l-1.39,5.08c0,0.13,0,0.21,0,0.21c0,0.66-0.15,1.15-0.46,1.48l-0.54,1.96l-0.58-0.16l0.37-1.35c-0.27,0.08-0.6,0.12-0.98,0.12l-4.91,0.01l-0.01,0.05c-0.13,0.48,0.16,0.98,0.64,1.11l7.15,1.91c0.48,0.13,0.98-0.16,1.11-0.64L19,7.08C19.13,6.6,18.85,6.1,18.36,5.97z M13.5,21.65l-0.58-0.16l2.39-8.73l0.58,0.16L13.5,21.65z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesPassport'
}
</script>
