<template lang="pug">
  .swiper-wr
    <!-- Slider main container -->
    .swiper-container.gallery-top
      .Gallery-close(
        id="descr_cross"
        @click="closeGallery"
      )
        IconCross

      <!-- Additional required wrapper main -->
      .swiper-wrapper
        .swiper-slide(
          v-for="(obj, i) in gallery" :key="i")
          .slide
            .slide-content(v-if="obj.type === 'image'")
              img(alt="" :src="getUrl(obj)")

            .slide-alt(v-if="obj.alt && obj.alt['ru']" v-html="obj.alt['ru']")

      .swiper-button-next.swiper-button-white
      .swiper-button-prev.swiper-button-white

    <!-- Additional wrapper thumbs -->
    .swiper-container-thumbs.gallery-thumbs
      .swiper-wrapper
        .swiper-slide(v-for="(obj, i) in gallery" :key="i")
          .swiper-slide-content(
            v-if="obj.type === 'image'"
            :style="`background-image:url(${getUrl(obj, 'thumb')})`"
          )

          .swiper-slide-alt.g-hide-mobile(
            v-if="obj.alt && obj.alt['ru']"
            v-html="obj.alt['ru']"
          )

          .swiper-slide-alt.g-hide-desktop(
            v-if="obj.alt && obj.alt['ru']"
            v-html="getAltMobile(obj.alt['ru'])"
          )
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import swiperConfig from "@/utils/swiperConfig";
import IconCross from "@/components/icons/IconCross";
import sendPostMessage from "@/utils/sendPostMessage";
export default {
  name: "Gallery",
  components: { IconCross },
  data () {
    return {
      typeFlight: null,
      category: null,
      apiUrl: null,
      galleryThumbs: [],
      galleryPhotos: [],
      from: [],
      gallery: [{}],
      keyListener: null
    }
  },
  computed: {
    ...mapState(['widgetId']),
  },
  methods: {
    ...mapMutations(['setWidgetId']),
    getGallery () {
      /* global Swiper */
      this.galleryThumbs = new Swiper('.gallery-thumbs', swiperConfig.thumbs)
      this.galleryPhotos = new Swiper('.gallery-top', swiperConfig.main(this.galleryThumbs))
      setInterval(() => {
        this.galleryPhotos.update()
        this.galleryThumbs.update()
      }, 1500)
    },
    closeGallery () {
      sendPostMessage('gallery-close', { id: this.widgetId })
    },
    exitByEsc (e) {
      if (e.key === 'Escape') {
        this.closeGallery()
      }
    },
    getUrl (obj, type) {
      if (type === 'thumb') {
        return this.checkApiUrl() + `gallery/data?id=${obj.id}&width=${window.innerWidth <= 750 ? 100 : 300}`
      } else {
        return this.checkApiUrl() + `gallery/data?id=${obj.id}&width=${window.screen.width}`
      }
    },
    checkApiUrl () {
      let response = this.apiUrl
      if (response) {
        if (response.slice(-1) !== '/') {
          response = response + '/'
        }
      }
      return response
    },
    getAltMobile (alt) {
      let response = alt
      if (response.length > 30) {
        response = response.substr(0, 30) + '...'
      }
      return response
    },
  },
  mounted () {
    this.keyListener = document.addEventListener('keyup', this.exitByEsc)
    window.addEventListener('message', (e) => {
      if (e.data && typeof e.data === 'string' && e.data.match(/{*/)[0]) {
        const res = JSON.parse(e.data)
        if (res.type === 'gallery-set-data') {
          this.typeFlight = res.data.type_flight
          this.category = res.data.category.id
          this.apiUrl = res.data.apiUrl
          if (res.data.gallery && res.data.gallery.length > 0) {
            this.gallery = res.data.gallery
            this.getGallery()
          }
        }
      }
    })
    const id = this.$route.params.id
    this.setWidgetId(id)
    sendPostMessage('gallery-mounted', { id })
  }
}
</script>

<style scoped>
.Gallery-close {
  position: absolute;
  right: 0;
  padding: 20px;
  z-index: 2;
  cursor: pointer;
}
.Gallery-close &:hover svg {
  opacity: 1;
}

.Gallery-close svg {
  fill: white;
  opacity: .5;
  height: 18px;
}

.swiper-wrapper {
  background-color: #0a0a0a;
}

.swiper-wr {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.gallery-top .swiper-slide {
 height: 85vh;
 background-color: #0a0a0a;
}

@media(max-width: $mobile-width) .gallery-top .swiper-slide {
  height: calc(100vh - 13vh - 60px);
}

.slide {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slide .slide-content {
  width: 100%;
  flex: 1;
  overflow: hidden;
  z-index: 1;
}

.slide .slide-content img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.slide .slide-content .iframe {
  width: 100%;
  height: 100%;
}

.slide .slide-alt {
  width: 100%;
  flex: none;
  background-color: rgba(0, 0, 0, .8);
  color: #fff;
  padding: 10px 15px;
  font-size: 19px;
  text-align: center;
  z-index: 2;
}

.swiper-container {
  flex: 1;
  width: 100%;
}

.swiper-container-thumbs {
  flex: none;
  width: 100%;
}

.gallery-thumbs {
  background-color: #0a0a0a;
  box-sizing: border-box;
}

@media(max-width: $mobile-width) .gallery-thumbs {
  padding-bottom: 50px
}

.gallery-thumbs .swiper-slide {
  margin-bottom: 1vw;
  margin-top: 1vw;
  height: 13vh;
  border: 1px solid transparent;
  border-top: solid 1px #0a0a0a;
  border-bottom: solid 1px #0a0a0a;
  width: 13vh;
  position: relative;
}

@media(max-width: $mobile-width) .gallery-thumbs .swiper-slide {
  width: 24%;
  margin-top: 0;
  margin-bottom: 10px;
}

.gallery-thumbs .swiper-slide-alt {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .8);
  color: #fff;
  padding: 5px 10px;
  z-index: 2;
}

@media(max-width: $mobile-width) .gallery-thumbs .swiper-slide-alt {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 1.2;
  bottom: auto;
  top: calc(100% + 1px);
}

.gallery-thumbs .swiper-slide-content {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.gallery-thumbs .swiper-slide-content .iframe {
  width: 200%;
  height: 200%;
  pointer-events: none;
}

.gallery-thumbs .swiper-slide-thumb-active {
  border: solid 1px #fff;
}
</style>
