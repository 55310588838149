<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path d="M8.77,6.85l-2.22,3.46c-0.14,0.22-0.39,0.34-0.65,0.32l-3.9-0.33c-0.62-0.05-1,0.66-0.61,1.15l2.5,3.07c0.16,0.2,0.2,0.47,0.11,0.71l-1.47,3.68c-0.23,0.58,0.35,1.15,0.92,0.92l3.62-1.49c0.25-0.1,0.51-0.05,0.72,0.12l3.15,2.64c0.48,0.39,1.19,0.03,1.16-0.58l-0.23-4.11c-0.02-0.26,0.11-0.52,0.34-0.65l3.3-1.97c0.54-0.32,0.42-1.14-0.2-1.29l-3.74-0.91c-0.26-0.07-0.46-0.26-0.52-0.51l-1-3.99C9.91,6.49,9.11,6.35,8.77,6.85z"/>
    <path d="M14.14,8.9l1.5-1.41c0.1-0.09,0.23-0.12,0.36-0.08l1.85,0.64c0.3,0.1,0.57-0.2,0.44-0.48l-0.84-1.8c-0.05-0.12-0.04-0.25,0.03-0.36l1.16-1.6c0.18-0.25-0.03-0.6-0.34-0.56l-1.94,0.28C16.23,3.56,16.1,3.5,16.02,3.4l-1.2-1.67c-0.18-0.25-0.57-0.16-0.63,0.14L13.8,3.89c-0.02,0.13-0.12,0.24-0.24,0.27l-1.84,0.56c-0.3,0.09-0.34,0.5-0.06,0.65l1.7,0.9c0.12,0.06,0.19,0.18,0.19,0.31l0,2.06C13.54,8.94,13.91,9.11,14.14,8.9z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesMisc'
}
</script>
