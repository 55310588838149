import axios from 'axios'
import queryString from 'query-string'

class API {
  debug = process.env.DEBUG

  constructor (baseUrl, lang) {
    this.lang = lang
    this.baseUrl = baseUrl
  }

  call (method, url, params = {}, apiUrl) {
    const self = this
    let token = null
    try {
      token = window.localStorage ? window.localStorage.getItem('travelmart_token') || null : null
    } catch (e) { console.log (e) }
    const isBaseUrlUniq = this.baseUrl !== 'https://dev.vipzal.dev.vip-zal.ru/widgets-api/v2/'
    if (apiUrl) {
      this.axios = axios.create({
        baseURL: apiUrl || this.baseUrl || 'https://dev.vipzal.dev.vip-zal.ru/widgets-api/v2/',
        timeout: 100000,
        headers: {
          'X-Lang': this.lang || 'ru',
          'Authorization': token ? `Bearer ${token}` : null
        }
      })
    } else if (isBaseUrlUniq) {
      this.axios = axios.create({
        baseURL: this.baseUrl || 'https://dev.vipzal.dev.vip-zal.ru/widgets-api/v2/',
        timeout: 100000,
        headers: {
          'X-Lang': this.lang || 'ru',
          'Authorization': token ? `Bearer ${token}` : null
        }
      })
    } else {
      this.axios = axios.create({
        baseURL: this.baseUrl || 'https://dev.vipzal.dev.vip-zal.ru/widgets-api/v2/',
        timeout: 100000,
        headers: {
          'X-Lang': this.lang || 'ru',
          'Authorization': token ? `Bearer ${token}` : null
        }
      })
    }
    const promise = this.axios({
      method,
      url,
      data: params
    })

    if (this.debug) { console.log('request', method, url, JSON.stringify(params)) }
    window.statusAxios = 'request'

    promise
      .then(response => {
        if (self.debug) { console.log('response', method, url, response.data) }
        window.statusAxios = 'response'
      })
      .catch(error => {
        if (self.debug) { console.log('error', method, url, error) }
        window.statusAxios = 'error'
      })

    return promise
  }

  fetchTerminals (query, apiUrl, params) {
    let req = params
    const lang = params.lang
    if (req.partner_id || req.source_host_id || req.ref_id) {
      req = `&${queryString.stringify(req)}`
    } else {
      req = ''
    }
    return this.call('get', `/terminals-autocomplete?lang=${lang}&query=${query}${req}`, {}, apiUrl)
  }

  fetchTerminal (params, apiUrl) {
    const req = queryString.stringify(params)
    return this.call('get', `/airports?${req}`, {}, apiUrl.replace('/v2', '/v3'))
  }

  fetchAirportByServiceId (id, apiUrl) {
    return this.call('get', `/airports?service_id=${id}`, {}, apiUrl.replace('/v2', '/v3'))
  }

  fetchService (id, apiUrl) {
    return this.call('get', `/services/${id}`, {}, apiUrl.replace('/v2', '/v3'))
  }

  fetchServices (params, apiUrl) {
    const req = queryString.stringify(params)
    return this.call('get', `/services?${req}`, {}, apiUrl.replace('/v2', '/v3'))
  }

  fetchPrice (id, apiUrl) {
    return this.call('get', `/service-prices-v2/${id}`, {}, apiUrl.replace('/v2', '/v3'))
  }
}

const api = new API(process.env.API_URL, 'ru')

export default {
  install: (Vue) => {
    Vue.prototype.$api = api
  }
}
