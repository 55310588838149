<template>
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path d="M12.1,12.26c1.11-0.42,2.24-0.66,3.08-0.66c1.87,0,1.06,6.07,1.06,6.07s0.9,0.08,1.23,0.2c0.54,0.19,0.04,1.12,0.04,1.12H14.2c0,0,0.61-3.95-0.16-4.65c-0.37-0.33-0.16,0.24-0.45,0.4C13.57,14.11,12.47,12.77,12.1,12.26z"/>
    <path d="M8.48,10.26c-1.76,0.2-2.53,0.94-2.53,0.58c0-2.36,0.66-4.39,2.53-4.39c1.24,0,1.95,3.02,1.95,4.03C10.44,10.81,9.79,10.1,8.48,10.26z"/>
    <path d="M8.47,11.75c2.03,0,3.68,1.65,3.68,3.68s-1.65,3.68-3.68,3.68s-3.68-1.65-3.68-3.68S6.44,11.75,8.47,11.75 M8.47,9.86c-3.08,0-5.57,2.5-5.57,5.57s2.5,5.57,5.57,5.57s5.57-2.5,5.57-5.57S11.55,9.86,8.47,9.86L8.47,9.86z"/>
    <path class="st0" d="M7.41,4.04c-0.31-2.5,3.41-2.93,3.41-0.11C10.82,6.77,7.71,6.45,7.41,4.04z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDisabledAccess'
}
</script>

<style scoped>
  .st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
