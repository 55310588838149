<template lang="pug">
  .ServiceMore.Form
    .ServiceMore-header(v-if="service && service.id")
      .ServiceMore-header-hover(@click="closeModal")
        .ServiceMore-header-icon(id="descr_arrow")
          IconArrowLeft

        .ServiceMore-header-text-terminal
          span.ServiceMore-header-text-terminal-name(v-if="terminal.label") {{ terminal.label }},&nbsp;
          span(v-if="service.type === 'departure'") вылет
          span(v-if="service.type !== 'departure'") прилёт

      .ServiceMore-header-box
        .ServiceMore-header-text
          .ServiceMore-header-text-terminal
            span.ServiceMore-header-text-terminal-name(v-if="terminal.label") {{ terminal.label }},&nbsp;
            span(v-if="service.type === 'departure'") вылет
            span(v-if="service.type !== 'departure'") прилёт

          .ServiceMore-header-text-service {{ service.name }} {{ convertedTypeText }}

        .ServiceMore-header-button(:class="{ 'show': showHeaderButton }")
          .ServicePriceBlock
            .Services-infoBlock-button.is-service-more
              .Button-next(@click.stop.prevent="order") Заказать

        .ServiceMore-header-button-close#descr_cross(@click="closeModal")
          IconCross

    .ServiceMore-content#ServiceMoreContent(
      v-if="service && service.id"
      @scroll="scrollContent"
    )
      .ServiceMore-main(:style="{ backgroundImage: `url(${getBackgroundImage})` }")
        .ServiceMore-main-block
          .ServiceMore-main-block-container
            template(v-if="service.price")
              .Services-infoBlock-price-value(v-if="service.price") {{ `${(service.price).toLocaleString('ru')} ₽` }}
              .Services-infoBlock-price-info при заказе для одного пассажира.

            .Services-infoBlock-price-info(v-if="!service.price") Стоимость мы сообщим вам после обработки заказа
            .Services-infoBlock-price-text.Services-infoBlock-price-text--children {{ service.info.tariffs_for_children }}
            ServiceMoreUrgency(
              :service="service"
              :terminal-name="terminal.name"
            )
            .ServicePriceBlock
              .Services-infoBlock-button.is-service-more
                .Button-next(@click.stop.prevent="order") Заказать

        .ServiceMore-photo--container(
          v-if="isGalleryEnable"
          @click="showGallery"
        )
          .ServiceMore-photo--block
            .ServiceMore-photo--icon
              IconPhoto

            .ServiceMore-photo--text Фотогалерея

      .ServiceMore-content-align
        .ServiceMore-content-align-left
          .ServiceMore-text
            .ServiceMore-location(v-if="hasLocationInfo || service.info.opening_hours")
              .Services-infoBlock-box-item.is-service-more.itemMin(v-if="hasLocationInfo")
                IconSubservicesMap.Services-infoBlock-box-item-icons
                span {{ locationText }}

              .Services-infoBlock-box-item.is-service-more(v-if="service.info.opening_hours")
                IconSubservicesClock.Services-infoBlock-box-item-icons
                span {{ service.info.opening_hours }}
                span(v-if="service.info.hours_to_stay") {{ dotAfterOpeningHours }}

              .Services-infoBlock-box-item.is-service-more(v-if="service.info.hours_to_stay")
                | {{ `Пребывание не дольше ${service.info.hours_to_stay} ч.` }}

            .ServiceMore-info(v-if="service.id")
              ServiceMoreCommon(:service="service")

        .ServiceMore-content-align-right.g-hide-mobile
          .ServiceMore-options
            ServiceDetails(
              :info="service.info"
              :service="service"
              :price="service.price"
              type="serviceMore"
              :no-price-block="true"
              :clear-background="true"
              step-info="3"
              location="hide"
            )

</template>

<script>
import { mapMutations, mapState } from 'vuex'
import IconCross from "@/components/icons/IconCross";
import IconArrowLeft from "@/components/icons/IconArrowLeft";
import IconPhoto from "@/components/icons/IconPhoto";
import IconSubservicesMap from "@/components/icons/options/IconSubservicesMap";
import IconSubservicesClock from "@/components/icons/options/IconSubservicesClock";
import ServiceMoreCommon from "@/components/ServiceMoreCommon";
import ServiceDetails from "@/components/ServiceDetails";
import ServiceMoreUrgency from "@/components/ServiceMoreUrgency";
import sendPostMessage from "@/utils/sendPostMessage";
import IconServiceDomestic from "@/components/icons/IconServiceDomestic";
import IconServiceInternational from "@/components/icons/IconServiceInternational";

export default {
  name: 'ServiceMore',
  components: {
    ServiceMoreUrgency,
    ServiceDetails,
    ServiceMoreCommon,
    IconSubservicesClock,
    IconSubservicesMap,
    IconPhoto,
    IconArrowLeft,
    IconCross,
    IconServiceDomestic,
    IconServiceInternational
  },
  data () {
    return {
      hasUrgentTariffs: true,
      showHeaderButton: false,
    }
  },
  computed: {
    ...mapState(['apiUrl', 'widgetId', 'terminal', 'service']),
    convertedTypeText () {
      if (this.service.type_flight === 'domestic') return 'для внутренних рейсов'
      if (this.service.type_flight === 'international') return 'для международных рейсов'
      return ''
    },
    getFlightTypeIconComponent () {
      switch (this.service.type_flight) {
        case 'domestic':
          return IconServiceDomestic
        case 'international':
          return IconServiceInternational
        default:
          return ''
      }
    },
    hasLocationInfo () {
      return this.service.info.how_to_find
    },
    dotAfterOpeningHours () {
      return this.service.info.opening_hours + this.service.info.opening_hours.slice(-1) === '.' ? '' : '.'
    },
    locationText () {
      return this.service.info.how_to_find
    },
    isGalleryEnable () {
      return this.service?.gallery?.length
    },
    getBackgroundImage () {
      let response = 'https://cdn.vip-zal.ru/images/default_bg.jpeg'
      if (this.service?.gallery?.length) {
        response = this.checkApiUrl() + `gallery/data?id=${this.service.gallery[0].id}&width=${window.screen.width}`
      }
      return response
    }
  },
  methods: {
    ...mapMutations(['setApiUrl', 'setTheme', 'setWidgetId', 'setTerminal', 'setService']),
    closeModal () {
      const id = this.widgetId
      sendPostMessage('details-close', { id })
    },
    scrollContent (e) {
      const container = e.target
      this.showHeaderButton = container.scrollTop > 0;
    },
    showGallery () {
      sendPostMessage('gallery-show', {
        id: this.widgetId
      })
    },
    checkApiUrl () {
      let response = this.apiUrl
      if (response && response.slice(-1) !== '/') {
        response = response + '/'
      }
      return response
    },
    order () {
      sendPostMessage('order', { id: this.widgetId })
    }
  },
  mounted () {
    this.setWidgetId(this.$route.params.id)
    sendPostMessage('details-mounted', { id: this.$route.params.id })
    window.addEventListener('message', (e) => {
      if (e.data && typeof e.data === 'string' && e.data.match(/{*/)[0]) {
        const res = JSON.parse(e.data)
        if (res.type === 'details-set-data') {
          this.setTerminal(res.data.terminal)
          this.setTheme(res.data.theme)
          this.setService(res.data.service)
          this.setApiUrl(res.data.apiUrl)
        }
      }
    })
  }
}
</script>

<style lang="stylus">
@import '../assets/variables.styl'

.ServiceMore
  display: flex
  flex-direction: column
  background: #fff
  height: 100vh
  border-radius: 10px
  @media(min-width: $mobile-width)
    position: relative
    &::-webkit-scrollbar { width: 0; }
    overflow-x: hidden
    overflow-y: auto
    scrollbar-width: none

  &-russianRuble
    svg.rub
      height: 23px
      fill: #fff

  &-buttons
    margin: 0 1.25rem

    .Button-next
      @extend .btn-theme

  &-service
    font-size: 18px

  &-content
    overflow: auto
    -webkit-overflow-scrolling: touch
    color #000
    //color: var(--text-color)

    &-align
      @media(min-width: $mobile-width)
        display: flex
        flex-direction: row-reverse
        align-items: flex-start
        justify-content: flex-end
        padding: 0 2.375rem 2.5rem
        max-width: 1300px
        margin: 0 auto

        &-left
          flex: 1

        &-right
          flex: none
          max-width: 19.75rem
          margin: 2.9375rem 0 0

  &-header
    display: flex
    align-items: flex-start
    justify-content: flex-start
    padding: .8125rem 0
    width: 100%
    background: var(--theme_main, #0b8ca7)
    color: #fff
    position: relative
    flex: none

    @media(min-width: $mobile-width)
      padding: .9rem 2.5rem .9rem 3.625rem
      display: block

    &-box
      display: flex
      flex-direction: row
      justify-content: space-between

      .ServiceMore-header-text-terminal
        @media(max-width: $mobile-width)
          display: block

        @media(min-width: $mobile-width)
          display: none

    &-hover
      opacity: .8
      cursor: pointer
      &:hover
        opacity: 1

      @media(min-width: $mobile-width)
        opacity: 1

    &-button
      display: none

      @media(min-width: $mobile-width)
        display: flex
        align-items: center
        justify-content: center
        position: relative
        top: -.5rem
        bottom: 0
        right: 3.43rem
        transition: opacity .3s ease

      .ServicePriceBlock
        opacity: 0

      &.show
        .ServicePriceBlock
          opacity: 1

      .Services-infoBlock-button
        padding: 0 !important
        margin: 0 !important

        .Button-next
          @extend .btn-theme

      &-close
        display: none !important

        &:hover
          svg
            opacity: 1

        @media(min-width: $mobile-width)
          display: block !important
          cursor: pointer
          margin-left: 4rem
          margin-right: 100px

          svg
            opacity: .8
            position: absolute
            width: 20px !important
            height: 20px !important
            fill: #fff !important

    &-icon
      display: flex
      align-items: center
      justify-content: center
      flex: none
      width: 22px
      padding-left 10px
      padding-right 10px
      margin-top: .2rem

      @media(min-width: $mobile-width)
        position: absolute
        left: .5125rem
        top: .5rem
        width: 3.5rem
        padding-right: 1rem
        display: none

      svg
        margin: 0 !important
        fill: #fff

        @media(min-width: $mobile-width)
          height: 1.5rem !important

    &-text
      padding-right: 2rem

      @media(min-width: $mobile-width)
        padding-right: 0

      &-terminal
        display: none
        font-size: .875rem

        @media(min-width: $mobile-width)
          display: block

      &-service
        font-size: 1.125rem

        @media(min-width: $mobile-width)
          font-size: 1.375rem

        &-flight
          display: flex
          align-content: flex-end
          font-size: var(--theme_font_size)
          line-height: 1
          &-type
            line-height: 1
            svg
              display: block
              flex: none
              height: calc(var(--theme_font_size) + 4px)
              fill: #fff
              margin-right: 5px
          span
            display: flex
            align-items: flex-end

  &-location
    background: #69696980
    padding: 1.25rem
    font-size: 13px
    opacity: .7

    @media(min-width: $mobile-width)
      float: right
      margin: 2.9375rem 0 1.5rem 2rem
      max-width: 21rem

    @media (min-width: $mobile-width) and (max-width: 1100px)
      float: none
      max-width: 100% !important
      margin-left: 1.25rem
      margin-bottom: 0

    .Services-infoBlock-box
      &.Services-infoBlock-box-dark
        padding-top: 14px

      &-item
        padding: 0 0 .5rem 2rem !important

        &:last-child
          padding-bottom: 0 !important

        svg
          left: 0
          fill: #000
  &-info
    padding: .8125rem 1.25rem 1.75rem

    @media(min-width: $mobile-width)
      padding: 1.875rem 1.25rem 1.75rem

    div.Services-infoBlock-price
      padding: 0 !important

      &-count
        padding-bottom: 7px

      &-value,
      &-info
        font-size: .875rem
        display: inline
        padding: 0 !important

      &-value
        padding-left: .35rem !important

      &-text
        padding-bottom: 7px !important

    div.ServiceMoreCommon
      padding: 0

      &-h1
        font-size: 1.875rem
        margin-bottom: .25rem
        line-height: 1.2

        @media(min-width: $mobile-width)
          font-size: 3rem
          margin-bottom: .5rem

      &-h2
        margin-bottom: .5625rem
        margin-top: 1.8125rem

        @media(min-width: $mobile-width)
          font-size: 1.5rem

      &-description,
      &-price
        margin-bottom: .6875rem

        & > .Services-infoBlock-price-text
          &:last-child
            padding-bottom: 0 !important

      &-penalties
        &--text
          p
            font-size: .875rem
            margin-bottom: .6875rem

            &:last-child
              margin-bottom: 0

      &-description
        p,
        ul,
        ol
          margin-bottom: .6875rem

          &:last-child
            margin-bottom: 0

        li
          margin-bottom: .35rem

          &:last-child
            margin-bottom: 0

        ul,
        ol
          list-style-position: inside

        ol
          counter-reset: schetchik 1
          list-style: none

          li
            display: flex
            align-items: flex-start
            justify-content: flex-start

            &::before
              flex: none
              width: 1.55rem
              content: counter(schetchik, decimal) "."
              counter-increment: schetchik 1

        ul
          li
            display: flex
            align-items: flex-start
            justify-content: flex-start

            &:before
              flex: none
              content: ''
              width: .8rem
              height: 1px
              background: #000
              margin-right: .75rem
              margin-top: .55rem

      div.td-fast-urgency
        &-item
          display: inline

  &-photo
    &--container
      z-index: 2
      position: relative
      color: #fff
      max-width: 1300px
      display: flex
      margin: 0 auto
      padding: 0 2.375rem
      flex-direction row-reverse
      @media(max-width: 700px)
        width: auto
        padding: 0
        height: 35px
        margin: 0 -2.5rem 0.4rem -2.5rem
        text-align center

    &--block
      width: 130px
      display: flex
      color: #fff
      background: rgba(0,0,0,0.25)
      cursor: pointer
      padding: 10px 25px
      @media(max-width: 700px)
        margin-bottom: -15px
        width: 100%
        padding-left: 40px

    &--icon
      svg
        fill: #fff
        width: 30px
        margin-top: 2px

    &--text
      margin-left: 12px
      margin-top: 5px
      font-size: inherit

  &-main
    padding: 2.625rem 2.5rem .5625rem
    background: #eceef1
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    position: relative

    @media(min-width: $mobile-width)
      padding: 2.625rem 0 0

    &::before
      content: ''
      display: block
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, .5)
      position: absolute
      top: 0
      left: 0
      z-index: 1

      @media(min-width: $mobile-width)
        display: none

    &-block
      position: relative
      z-index: 2
      color: #fff

      @media(min-width: $mobile-width)
        max-width: 1300px
        margin: 0 auto
        padding: 0 3.625rem

      &-container
        @media(min-width: $mobile-width)
          display: table
          background: #021c2180
          padding: 2.775rem 1.7rem 1.3rem 5rem
          margin: 2.75rem 0 4.3rem
          box-shadow: 0 0 9.5rem 5rem rgba(255, 255, 255, .35)
          min-width: 75%

    .Services-infoBlock-button
      padding-top: .625rem !important

      .Button-next
        @extend .btn-theme

    .Services-infoBlock-price-value
      font-size: 2.25rem
      padding: 0

    .Services-infoBlock-price-info
      font-size: 1.125rem
      margin-bottom: 1rem

    .Services-infoBlock-price-text
      opacity: .8

      &-urgent-link
        border-bottom: 1px dotted var(--main)
        text-decoration: none
        color: var(--main)
        cursor: pointer

        &:hover
          color: var(--secondary)
          border-bottom-color: var(--secondary)

  &-options
    padding: 18px 1.25rem
    background: var(--background, #eceef1)
    margin: 1.8125rem -1.25rem 0

    @media(min-width: $mobile-width)
      padding: 0 1.25rem .625rem
      margin: 0
      background: transparent

    .Services-infoBlock
      &-box-dark
        padding-top: 0

      &-box
        background: none !important
        padding-bottom: 0

        &-item
          padding-left: 0
          padding-bottom: calc(.5625rem + 7px)
          font-size: 13px
          opacity: .7
          &.is-service-more
            color: #fff

          @media(min-width: $mobile-width)
            padding-left: 2rem

          &:last-child
            padding-bottom: 0

          svg
            position: static
            left: 0
            width: 22px
            height: 25px
            top: -8px
            margin: -3px 0 4px -2px
</style>
