<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <g>
      <path d="M7.39,8.87L7.39,8.87C7.6,8.63,7.57,8.28,7.33,8.09C7.1,7.89,6.75,7.92,6.55,8.16c-0.2,0.23-0.17,0.58,0.07,0.78c0.06,0.05,0.12,0.08,0.18,0.1C7.01,9.11,7.24,9.05,7.39,8.87z"/>
      <path d="M8.1,12.17L8.1,12.17c0.16-0.28,0.06-0.61-0.2-0.76c-0.26-0.16-0.61-0.05-0.76,0.21c-0.15,0.27-0.06,0.61,0.21,0.76c0.03,0.02,0.07,0.03,0.1,0.04C7.7,12.5,7.97,12.4,8.1,12.17z"/>
      <path d="M8.64,9.69c0.03,0.02,0.06,0.03,0.1,0.04C8.99,9.82,9.26,9.72,9.4,9.48L9.4,9.47c0.15-0.26,0.06-0.6-0.21-0.75C8.93,8.57,8.58,8.67,8.43,8.93C8.28,9.2,8.37,9.54,8.64,9.69z"/>
      <path d="M9.2,17.7c-0.29-0.1-0.61,0.06-0.7,0.35c-0.1,0.29,0.06,0.6,0.35,0.7c0.29,0.1,0.6-0.06,0.7-0.35l0-0.01C9.65,18.11,9.49,17.79,9.2,17.7z"/>
      <path d="M6.86,14.1c-0.28-0.13-0.61,0.01-0.73,0.28c-0.13,0.28,0,0.61,0.28,0.73c0.02,0.01,0.04,0.01,0.05,0.02c0.27,0.09,0.56-0.04,0.68-0.3l0-0.01C7.27,14.55,7.14,14.23,6.86,14.1z"/>
      <path d="M9.53,14.78c-0.29-0.1-0.61,0.07-0.7,0.36c-0.1,0.29,0.06,0.6,0.35,0.7c0.29,0.1,0.6-0.06,0.7-0.35l0-0.01C9.98,15.18,9.82,14.87,9.53,14.78z"/>
      <path d="M3.48,13.36c-0.2,0.23-0.17,0.58,0.07,0.78c0.06,0.05,0.12,0.08,0.18,0.1c0.21,0.07,0.45,0.01,0.6-0.17l0.01-0.01c0.2-0.23,0.17-0.58-0.07-0.78C4.03,13.09,3.68,13.12,3.48,13.36z"/>
      <path d="M2.66,16.16c-0.2,0.23-0.17,0.58,0.07,0.78c0.06,0.05,0.12,0.08,0.18,0.1c0.21,0.07,0.45,0.01,0.6-0.17l0.01-0.01c0.2-0.23,0.17-0.58-0.07-0.77C3.21,15.89,2.86,15.92,2.66,16.16z"/>
      <path d="M5.68,11.41l0.01-0.01c0.18-0.25,0.11-0.59-0.14-0.77c-0.25-0.18-0.6-0.11-0.77,0.14c-0.18,0.25-0.12,0.6,0.13,0.77c0.05,0.03,0.09,0.06,0.14,0.07C5.28,11.7,5.54,11.62,5.68,11.41z"/>
      <path d="M6.33,17.03c-0.26-0.15-0.61-0.05-0.76,0.21C5.42,17.5,5.51,17.84,5.78,18c0.03,0.02,0.06,0.03,0.1,0.05c0.24,0.08,0.52-0.02,0.65-0.25l0.01-0.01C6.69,17.51,6.6,17.18,6.33,17.03z"/>
      <path d="M14.63,14.18c0.05,0.02,0.1,0.03,0.16,0.03c0.31,0.01,0.56-0.23,0.57-0.54l0-0.01c0.01-0.31-0.23-0.56-0.54-0.56c-0.31-0.01-0.56,0.23-0.57,0.54C14.25,13.89,14.41,14.1,14.63,14.18z"/>
      <path d="M14.63,16.7c0.06,0.02,0.13,0.03,0.21,0.03c0.31-0.02,0.54-0.28,0.52-0.59l0-0.01c-0.02-0.31-0.28-0.54-0.59-0.51c-0.31,0.02-0.54,0.29-0.52,0.59C14.27,16.44,14.43,16.63,14.63,16.7z"/>
      <path d="M14.64,11.69c0.06,0.02,0.13,0.03,0.21,0.03c0.31-0.02,0.54-0.28,0.52-0.58l0-0.01c-0.02-0.3-0.28-0.54-0.59-0.51c-0.31,0.02-0.54,0.29-0.52,0.59C14.27,11.43,14.43,11.62,14.64,11.69z"/>
      <path d="M10.19,12.04c-0.29-0.1-0.61,0.06-0.7,0.36c-0.1,0.29,0.06,0.6,0.35,0.7c0.29,0.1,0.6-0.06,0.7-0.35l0-0.01C10.64,12.44,10.49,12.13,10.19,12.04z"/>
      <path d="M14.93,18.44c-0.31,0.02-0.54,0.28-0.52,0.59c0.01,0.23,0.17,0.42,0.38,0.49c0.06,0.02,0.13,0.03,0.21,0.03c0.31-0.02,0.54-0.28,0.52-0.59l0-0.01C15.49,18.65,15.23,18.42,14.93,18.44z"/>
      <path d="M12.99,9.98c-0.3-0.04-0.58,0.18-0.62,0.49c-0.03,0.27,0.13,0.51,0.37,0.59c0.04,0.01,0.07,0.02,0.11,0.02c0.3,0.04,0.58-0.18,0.62-0.48l0-0.01C13.5,10.29,13.28,10.02,12.99,9.98z"/>
      <path d="M14.73,5.15l-0.88-0.29l0.95-2.85h-2.47l-0.7,2.12l-0.88-0.29C7.12,4.19,7.12,4.19,6.54,5.94l10.3,3.42C17.42,7.62,17.42,7.62,14.73,5.15z"/>
      <path d="M11.44,10.04L11.44,10.04c0.1-0.3-0.06-0.61-0.35-0.7c-0.29-0.1-0.61,0.06-0.7,0.35c-0.1,0.29,0.06,0.6,0.35,0.7C11.03,10.49,11.35,10.33,11.44,10.04z"/>
      <path d="M12.06,18.23c-0.3-0.04-0.58,0.18-0.62,0.49c-0.03,0.27,0.13,0.51,0.37,0.59c0.04,0.01,0.07,0.02,0.11,0.02c0.3,0.04,0.58-0.18,0.62-0.48l0-0.01C12.58,18.54,12.36,18.26,12.06,18.23z"/>
      <path d="M12.48,12.65c-0.3-0.04-0.58,0.18-0.62,0.49c-0.03,0.27,0.13,0.51,0.37,0.59c0.04,0.01,0.07,0.02,0.11,0.02c0.3,0.04,0.58-0.18,0.62-0.48l0-0.01C13,12.96,12.79,12.68,12.48,12.65z"/>
      <path d="M12.21,15.36c-0.3-0.07-0.6,0.12-0.66,0.42c-0.06,0.28,0.1,0.56,0.37,0.65c0.02,0.01,0.04,0.01,0.05,0.02c0.3,0.07,0.59-0.12,0.66-0.42l0-0.01C12.69,15.72,12.51,15.42,12.21,15.36z"/>
    </g>
</svg>
</template>

<script>
export default {
  name: 'IconSubservicesWash'
}
</script>
