<template>
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M8.14,7.67c-2.11,0-2.17-3.33-0.15-3.33C10.39,4.34,10.21,7.67,8.14,7.67z"/>
    <path class="st0" d="M4.71,11.71c-2.37,0-2.43-3.72-0.16-3.72C7.23,7.99,7.03,11.71,4.71,11.71z"/>
    <path class="st0" d="M17.59,8.18l-1.05,0.65c0,0,1.83,6.2,1.91,6.45c-5.01,4.99-8.72,2.41-12.95,3.99l-0.23-0.82c4.89-1.55,7.7,0.58,12.16-3.47c-0.42-1.78-1.95-6.86-2.29-7.89c-2.69,1.87-5.08,2.14-7.33,2.28c0.24,1.77-0.86,3.74-3.09,3.74c-0.27,0-0.51-0.03-0.74-0.08l1.35,4.07c-0.74,0.19-1.49,0.47-2.23,0.88c-0.08-0.26-1.55-5.19-1.7-5.74c0.25-0.18,0.5-0.33,0.75-0.48c-0.23-0.34-0.39-0.73-0.49-1.14c-0.53,0.29-1.06,0.65-1.6,1.11c0.61,2.17,2.3,7.99,2.3,7.99c0.62-0.35,1.21-0.63,1.77-0.86l0.67,2.16c4.39-2.61,9.39,0.98,14.97-5.39C19.35,13.85,17.59,8.18,17.59,8.18z M14.38,8.94c0.34,1.13,1.26,4.16,1.64,5.64c-0.99,0.85-2.02,1.33-3.08,1.6l-1.76-5.99C12.17,9.98,13.23,9.6,14.38,8.94z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTaxRefund'
}
</script>

<style scoped>
  .st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
