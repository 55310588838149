import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from "vue-router";
import store from './store'
import Colors from './plugins/colors'
import Api from './plugins/api'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

// Vue.use(VueAwesomeSwiper)
Vue.use(VueRouter)
Vue.use(Colors)
Vue.use(Api)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
