<template lang="pug">
  .Service-boxBottom
    .Service-boxBottom-cost(v-if="service.price")
      span.price {{ (service.price).toLocaleString('ru') }}
      IconRuble
      span.separate /
      IconMan

    .Order(@click="order") Заказать
</template>

<script>
import { mapState } from 'vuex'
import IconRuble from '@/components/icons/IconRuble';
import IconMan from '@/components/icons/IconMan';
import sendPostMessage from '@/utils/sendPostMessage';
export default {
  name: 'Order',
  props: ['id'],
  components: { IconRuble, IconMan },
  computed: {
    ...mapState(['service', 'widgetId'])
  },
  methods: {
    order () {
      sendPostMessage('order', { id: this.widgetId })
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import '../assets/variables.styl'
  .Order
    @extend .btn-theme

  .Service
    &-boxBottom
      display: flex
      flex-wrap: wrap
      align-items: center
      &-cost
        display: flex
        align-items: flex-end
        font-size: var(--theme_header_size)
        line-height: 1
        padding: ($article-gap - 14px) ($article-gap - 12px) ($article-gap - 14px) 0
        .price
          display: block
          padding-right: ($article-gap - 19px)

        .separate
          padding: 0 ($article-gap - 22px)

        svg
          height: var(--theme_header_size)
          fill: var(--theme_text_color)
          position: relative
          top: -1px
</style>
