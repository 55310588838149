<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <g>
      <path class="st0" d="M18.47,9.4h-2.15l0.12,4.72c0,1.25-0.18,1.53-1.58,1.62l-6.69,0.39l0,1.84c0,0.29,0.24,0.53,0.53,0.53h4.63l2.46,2.46c0.21,0.21,0.54,0.21,0.75,0c0.1-0.1,0.15-0.24,0.15-0.37V18.5h1.77c0.29,0,0.53-0.24,0.53-0.53V9.93C18.99,9.64,18.76,9.4,18.47,9.4z"/>
      <path class="st0" d="M14.86,14.64c0.29,0,0.53-0.24,0.53-0.53l-0.12-8.6c0-0.29-0.24-0.53-0.53-0.53H1.53c-0.29,0-0.53,0.24-0.53,0.53v9.1c0,0.29,0.24,0.53,0.53,0.53h1.77l0,2.09c0,0.13,0.05,0.27,0.15,0.37c0.21,0.21,0.54,0.21,0.75,0l2.46-2.46L14.86,14.64z M3.64,7.1H9c0.29,0,0.53,0.24,0.53,0.53c0,0.29-0.24,0.53-0.53,0.53H3.64c-0.29,0-0.53-0.24-0.53-0.53C3.11,7.34,3.35,7.1,3.64,7.1z M3.64,9.21h9c0.29,0,0.53,0.24,0.53,0.53c0,0.29-0.24,0.53-0.53,0.53h-9c-0.29,0-0.53-0.24-0.53-0.53S3.35,9.21,3.64,9.21z M3.64,12.37c-0.29,0-0.53-0.24-0.53-0.53c0-0.29,0.24-0.53,0.53-0.53h7.03c0.29,0,0.53,0.24,0.53,0.53c0,0.29-0.24,0.53-0.53,0.53H3.64z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesConversation'
}
</script>

<style scoped>
</style>
