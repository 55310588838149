<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <g>
      <polygon points="7.87,20 2.61,20.11 1.53,6.78 0.53,6.78 1.8,21 7.77,21 "/>
      <g>
        <path d="M9.78,9.58c-0.06-0.21-0.1-0.42-0.1-0.65c0-1.35,1.1-2.5,2.45-2.5c1.26,0,2.29,1.01,2.42,2.23c0.2-0.1,0.4-0.18,0.62-0.23c-0.24-1.47-1.51-2.6-3.04-2.6c-1.71,0-3.09,1.39-3.09,3.09c0,0.19,0.02,0.37,0.06,0.56C9.33,9.48,9.56,9.52,9.78,9.58z"/>
        <path d="M13.02,11.21c-0.27,0.11-0.57,0.17-0.88,0.17c-0.12,0-0.24-0.02-0.35-0.04c0.08,0.21-0.16,0.61-0.16,0.61l1.85-0.02C13.47,11.93,13.02,11.43,13.02,11.21z"/>
      </g>
      <path d="M8.63,11.37C8.7,11.65,8.66,11.98,8.24,12c-0.29,0.02-0.55-0.03-0.63-0.39c-0.09-0.39,0.11-0.56,0.39-0.63C8.5,10.86,8.52,10.95,8.63,11.37z"/>
      <path d="M14.35,10.13c0.04-0.3,0.16-0.58,0.52-0.52c0.34,0.06,0.6,0.25,0.52,0.52c-0.12,0.43-0.17,0.56-0.52,0.52C14.37,10.6,14.31,10.42,14.35,10.13z"/>
      <path d="M9.33,11.18c0.14,0.36,0.17,0.56,0.68,0.3c0.26-0.13,0.3-0.22,0.22-0.68c-0.05-0.3-0.23-0.37-0.59-0.3C9.06,10.61,9.15,10.72,9.33,11.18z"/>
      <path d="M11.09,8.05c0-0.29,0.07-0.54,0.52-0.52c0.41,0.02,0.5,0.17,0.52,0.52c0.02,0.29-0.24,0.46-0.52,0.52C11.19,8.67,11.09,8.34,11.09,8.05z"/>
      <path d="M17.49,11.37c0-0.29-0.07-0.54-0.52-0.52c-0.41,0.02-0.5,0.17-0.52,0.52c-0.02,0.29,0.24,0.46,0.52,0.52C17.38,11.99,17.49,11.66,17.49,11.37z"/>
      <path class="st0" d="M8.99,19.72L8.68,21l7.16,0l-0.45-1.44c2.6-1.07,4.61-3.41,4.61-7.12c-2.79,0-13.95,0.18-15.96,0.18C4.04,16.4,6.23,18.74,8.99,19.72z"/>
      <path d="M6.51,12.01C6.7,10.76,7.77,9.8,9.07,9.8c1.28,0,2.34,0.93,2.55,2.15l0.64-0.01c-0.22-1.57-1.56-2.78-3.19-2.78c-1.66,0-3.2,1.26-3.2,2.86L6.51,12.01z"/>
      <path d="M13.47,11.93c-0.09-0.25-0.14-0.52-0.14-0.8c0-1.35,1.1-2.45,2.45-2.45s2.45,1.1,2.45,2.45c0,0.26-0.05,0.51-0.13,0.75l0.67-0.01c0.06-0.24,0.09-0.48,0.1-0.74c0.08-1.77-1.32-3.15-3.09-3.09c-1.71,0.05-3.09,1.38-3.09,3.09c0,0.28,0.05,0.55,0.12,0.81L13.47,11.93z"/>
      <polygon class="st0" points="9.41,4.53 9,3 1.2,3.29 0.83,4.53 0,4.53 0,6 10,5.83 10,4.53 "/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesFood'
}
</script>

<style scoped>
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
</style>
