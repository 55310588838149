<template>
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M14.61,13.03l-3.66-1.78L12.13,6l1.34,1.81l2.7,0.39l0.52,1.24c0,0,1.65,0.61,1.89,0.71C18.92,12.06,16.44,13.75,14.61,13.03z M13.23,19.85c0.34,0,0.71,0.43,0.71,1.12h-2.59l-0.12-3.35l-5.27-0.16l-1.57,2.39c0,0,1.01,0.04,0.93,1.12c-0.94,0-3.21,0-3.21,0l2.33-9.23l0.7-0.02l0.48,1.71l4.79-1.14l3.97,1.8L13.23,19.85z M7.68,18.28l-1.02,1.58c0,0,1.01,0.04,0.93,1.12c-0.28,0-1.49,0-1.49-0.01c0-0.7-0.25-1.27-0.7-1.27l0.77-1.58L7.68,18.28z M10.58,21.01H9.56l-0.09-2.72l1.22,0.05L10.58,21.01z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconAnimalsAllowed'
}
</script>

<style scoped>
  .st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
