<template>
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st9" d="M11.19,19.9l-6.1,0l0.02-4.75l3.88,0.08l7.75-7.79L20,10.85L11.19,19.9z M11.67,14.76l-1.91,1.79l-2.91,0.12l-0.22,1.87l3.69,0.17l2.53-2.65L11.67,14.76z M3.68,8.97L3.05,8.41C3.4,7.99,3.58,7.45,3.5,6.95c-0.14-0.88-0.74-0.8-1.05-1.8C2.2,4.36,2.89,3.11,3.63,2.57l0.63,0.59c-0.45,0.37-0.44,0.91-0.3,1.37c0.25,0.82,0.9,0.79,1.05,1.82C5.13,7.22,4.52,8.49,3.68,8.97z M1.57,8.82c0.19,0.83,1.27,1.1,1.51,2.13c0.2,0.84-0.64,2.29-1.48,2.77l-0.64-0.56c0.42-0.41,0.72-1.12,0.61-1.61c-0.21-0.88-1.28-1.08-1.52-2.11c-0.22-0.93,0.59-2.2,1.34-2.74L2.02,7.3C1.57,7.67,1.43,8.26,1.57,8.82z M1.92,15.14l0.03,4.71l-1.57-0.02l0.2-4.69C0.58,15.14,1.92,15.14,1.92,15.14z M4.05,15.14l0.1,4.74L2.7,19.86l0.16-4.72L4.05,15.14z"/>
    <path class="st9" d="M11.19,19.9L5,19.9l0.02-4.93l3.97,0.08l10.95,0.17L20,19.9H11.19z M13.98,16.01l-7.57,0.12l-0.22,2.66l7.91,0.17L13.98,16.01z M3.68,8.97L3.05,8.41C3.4,7.99,3.58,7.45,3.5,6.95c-0.14-0.88-0.74-0.8-1.05-1.8C2.2,4.36,2.89,3.11,3.63,2.57l0.63,0.59c-0.45,0.37-0.44,0.91-0.3,1.37c0.25,0.82,0.9,0.79,1.05,1.82C5.13,7.22,4.52,8.49,3.68,8.97z M1.57,8.82c0.19,0.83,1.27,1.1,1.51,2.13c0.2,0.84-0.64,2.29-1.48,2.77l-0.64-0.56c0.42-0.41,0.72-1.12,0.61-1.61c-0.21-0.88-1.28-1.08-1.52-2.11c-0.22-0.93,0.59-2.2,1.34-2.74L2.02,7.3C1.57,7.67,1.43,8.26,1.57,8.82z M2.02,14.97l0.03,4.88l-1.28-0.02l0.2-4.86C0.98,14.97,2.02,14.97,2.02,14.97z M4.05,14.97l0.1,4.91L2.7,19.86l0.16-4.89L4.05,14.97z"/>
    <path class="st0" d="M18.49,10.89L8.33,13.95l-3.81,1.13L6,19.78l5.9-1.88l8.07-2.58L18.49,10.89z M6.63,18.39l-0.59-2.61l7.02-2.33l1.01,2.77L6.63,18.39z"/>
    <path class="st0" d="M3.68,9.57L3.05,9.01C3.4,8.6,3.58,8.06,3.5,7.56c-0.14-0.88-0.74-0.8-1.05-1.8C2.2,4.97,2.89,3.72,3.63,3.17l0.63,0.59c-0.45,0.37-0.44,0.91-0.3,1.37c0.25,0.82,0.9,0.79,1.05,1.82C5.13,7.82,4.52,9.1,3.68,9.57z"/>
    <path class="st0" d="M1.57,9.43c0.19,0.83,1.27,1.1,1.51,2.13c0.2,0.84-0.64,2.29-1.48,2.77l-0.64-0.56c0.42-0.41,0.72-1.12,0.61-1.61c-0.21-0.88-1.28-1.08-1.52-2.11c-0.22-0.93,0.59-2.2,1.34-2.74L2.02,7.9C1.57,8.27,1.43,8.86,1.57,9.43z"/>
    <polygon class="st0" points="1.6,16.01 3.1,20.66 1.7,21.09 0.02,16.52 "/>
    <polygon class="st0" points="3.73,15.33 5.32,19.98 3.8,20.45 2.47,15.74 "/>
  </svg>
</template>

<script>
export default {
  name: 'IconSmokingAreas'
}
</script>

<style scoped>
  .st0{fill-rule:evenodd;clip-rule:evenodd;}
  .st9{display:none;fill-rule:evenodd;clip-rule:evenodd;}
</style>
