<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 469.7 409.2" style="enable-background:new 0 0 469.7 409.2;" xml:space="preserve">
    <path class="st0" d="M0,204.6l200.7,200.8c5.1,5.1,13.4,5.1,18.5,0l27.6-27.7c5.1-5.1,5.1-13.3,0-18.4L125,237.3h331.6c7.2,0,13-5.8,13-13V185c0-7.2-5.8-13-13-13H124.1L246.8,49.6c5.1-5.1,5.1-13.4,0-18.5L219.3,3.8c-5.1-5.1-13.3-5.1-18.4,0L0,204.6z"/>
  </svg>
</template>
<script>
export default {
  name: 'IconArrowLeft'
}
</script>
<style lang="stylus">
  svg
    height 20px
</style>
