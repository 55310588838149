import Router from 'vue-router'
import ServiceMore from '@/components/ServiceMore'
import Gallery from '@/components/Gallery'
import Core from '@/Core'

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Core',
      component: Core
    },
    {
      path: '/details/:id',
      component: ServiceMore,
      props: true
    },
    {
      path: '/gallery/:id',
      component: Gallery
    }
  ]
})
