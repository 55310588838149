<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M17.06,11.8c-0.43,0-0.26-0.01-1.13-0.02L15.86,7.1l-2.93-2.89L4.01,4.25l0,7.46c-0.66-0.01-0.15-0.01-0.56-0.01c-0.9-0.01-1.37,0.26-1.37,1.32c0,1.07-0.11,7.99-0.11,7.99h16.04c0,0,0-6.98,0-7.85C18.01,12.28,17.59,11.81,17.06,11.8z M5.03,5.25l7.37-0.01l-0.05,2.7l2.53-0.21l0.09,6.19L4.93,14.07L5.03,5.25z M4.75,18.92l-0.03-1.61l10.43,0.29l0,1.31L4.75,18.92z M16.71,15.92c-0.4,0-0.43-0.21-0.43-0.46c0-0.26,0.03-0.46,0.43-0.46c0.4,0,0.41,0.21,0.41,0.46C17.12,15.72,17.11,15.92,16.71,15.92z M16.71,13.94c-0.4,0-0.43-0.21-0.43-0.46c0-0.26,0.03-0.46,0.43-0.46c0.4,0,0.41,0.21,0.41,0.46C17.12,13.73,17.11,13.94,16.71,13.94z"/>
    <g class="st4">
      <path class="st5" d="M7.22,8.93C7.22,8.93,7.22,8.93,7.22,8.93C7.21,8.94,7.21,8.94,7.22,8.93L7.16,8.94c0,0,0,0-0.01,0s0,0,0-0.01V8.26H7.08v0.67c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H7.03c0,0,0,0-0.01,0s0,0,0-0.01l0-0.68V8.2v0l0,0c0,0,0,0,0,0c0,0,0,0,0.01,0h0.18c0,0,0,0,0.01,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0.05V8.93z"/>
      <path class="st5" d="M7.46,8.23c0,0,0.01,0.01,0.01,0.01c0,0,0,0.01,0.01,0.02c0,0.01,0,0.02,0,0.03v0.54c0,0.01,0,0.02,0,0.03c0,0.01,0,0.01-0.01,0.02c0,0,0,0.01-0.01,0.01C7.45,8.91,7.44,8.92,7.43,8.93C7.41,8.93,7.4,8.94,7.38,8.94c-0.01,0-0.03,0-0.04-0.01C7.33,8.92,7.32,8.91,7.31,8.9C7.3,8.89,7.29,8.88,7.29,8.87c0-0.01-0.01-0.02-0.01-0.03V8.29c0-0.01,0-0.02,0.01-0.03S7.3,8.24,7.31,8.23C7.32,8.22,7.33,8.21,7.34,8.2C7.36,8.2,7.37,8.19,7.38,8.19c0.02,0,0.03,0,0.04,0.01S7.45,8.22,7.46,8.23z M7.42,8.29c0-0.01,0-0.02-0.01-0.02S7.39,8.26,7.38,8.26s-0.02,0-0.03,0.01C7.35,8.28,7.35,8.28,7.35,8.29v0.54c0,0.01,0,0.02,0.01,0.02c0.01,0.01,0.02,0.01,0.03,0.01s0.02,0,0.03-0.01s0.01-0.02,0.01-0.02V8.29z"/>
      <path class="st5" d="M7.88,8.46c0,0.01-0.01,0.02-0.02,0.03C7.87,8.5,7.87,8.51,7.88,8.52s0.01,0.02,0.01,0.04v0.37c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H7.83c-0.01,0-0.01,0-0.01-0.01V8.55c0-0.01,0-0.02-0.01-0.02C7.8,8.52,7.79,8.52,7.79,8.52H7.75v0.41c0,0,0,0,0,0.01l0,0c0,0,0,0,0,0c0,0,0,0,0,0H7.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0V8.52H7.65c-0.01,0-0.02,0-0.03,0.01C7.62,8.54,7.61,8.54,7.61,8.55v0.37c0,0.01,0,0.01-0.01,0.01H7.56c0,0-0.01,0-0.01,0c0,0,0,0,0-0.01V8.55c0-0.01,0-0.02,0.01-0.04c0-0.01,0.01-0.02,0.02-0.03C7.57,8.48,7.56,8.47,7.56,8.46c0-0.01-0.01-0.02-0.01-0.04V8.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0H7.6c0,0,0.01,0,0.01,0c0,0,0,0,0,0.01v0.22c0,0.01,0,0.02,0.01,0.02c0.01,0.01,0.02,0.01,0.03,0.01h0.04V8.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0.04c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.01v0.25h0.04c0.01,0,0.02,0,0.02-0.01c0.01-0.01,0.01-0.01,0.01-0.02V8.2c0,0,0,0,0-0.01s0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.22C7.89,8.43,7.88,8.45,7.88,8.46z"/>
      <path class="st5" d="M8.04,8.19c0.01,0,0.03,0,0.04,0.01C8.1,8.21,8.11,8.21,8.11,8.22s0.02,0.02,0.02,0.03c0.01,0.01,0.01,0.03,0.01,0.04v0.63c0,0.01,0,0.01-0.01,0.01H8.09c0,0,0,0-0.01,0s0,0,0-0.01V8.69H8.01v0.23c0,0,0,0,0,0.01s0,0-0.01,0H7.95c0,0,0,0-0.01,0s0,0,0-0.01V8.29c0-0.01,0-0.03,0.01-0.04s0.01-0.02,0.02-0.03S7.99,8.21,8.01,8.2C8.02,8.2,8.03,8.19,8.04,8.19z M8.08,8.29c0-0.01,0-0.02-0.01-0.03S8.05,8.26,8.04,8.26s-0.02,0-0.03,0.01C8.01,8.28,8.01,8.28,8.01,8.29v0.34h0.07V8.29z"/>
      <path class="st5" d="M8.41,8.93C8.41,8.93,8.4,8.93,8.41,8.93C8.4,8.94,8.4,8.94,8.41,8.93L8.35,8.94c0,0,0,0-0.01,0c0,0,0,0,0-0.01V8.26H8.27v0.58c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0.01-0.01,0.02-0.02,0.03C8.23,8.92,8.22,8.93,8.2,8.93v0c-0.01,0-0.02,0.01-0.03,0.01h0c0,0,0,0-0.01,0c0,0,0,0,0-0.01V8.88c0-0.01,0-0.01,0.01-0.01c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01-0.01C8.2,8.86,8.2,8.85,8.2,8.84V8.25V8.2c0,0,0,0,0,0l0,0v0c0,0,0,0,0.01,0H8.4c0,0,0,0,0.01,0c0,0,0,0,0,0.01V8.93z"/>
      <path class="st5" d="M8.67,8.84c0,0.01,0,0.03-0.01,0.04c0,0.01-0.01,0.01-0.01,0.02c0,0.01-0.01,0.01-0.01,0.02C8.63,8.92,8.62,8.93,8.61,8.93c-0.01,0-0.02,0.01-0.03,0.01H8.53c0,0,0,0,0,0c0,0,0,0,0-0.01V8.88c0-0.01,0-0.01,0.01-0.01l0.04,0c0.01,0,0.02,0,0.02-0.01C8.6,8.86,8.61,8.85,8.61,8.84V8.72c-0.01,0-0.02,0.01-0.04,0.01s-0.03,0-0.04-0.01c-0.01,0-0.01-0.01-0.02-0.01S8.51,8.7,8.5,8.7S8.49,8.69,8.49,8.68c0,0-0.01-0.01-0.01-0.02C8.47,8.65,8.47,8.64,8.47,8.63V8.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01l0,0.42c0,0.01,0,0.02,0.01,0.02s0.02,0.01,0.03,0.01c0.01,0,0.02,0,0.03-0.01C8.6,8.64,8.61,8.64,8.61,8.63V8.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0.01,0,0.01,0c0,0,0,0,0,0.01V8.84z"/>
      <path class="st5" d="M8.74,8.08L8.74,8.08C8.74,8.08,8.74,8.08,8.74,8.08l0-0.05c0,0,0,0,0-0.01c0,0,0,0,0,0l0.1,0.04l0.09-0.04c0,0,0,0,0,0c0,0,0,0,0,0.01v0.04c0,0,0,0.01,0,0.01h0L8.83,8.12L8.74,8.08z M8.93,8.93C8.93,8.93,8.93,8.93,8.93,8.93C8.93,8.94,8.93,8.94,8.93,8.93L8.88,8.94c0,0,0,0-0.01,0s0,0,0-0.01v-0.4L8.8,8.61v0.32c0,0,0,0,0,0.01c0,0,0,0-0.01,0H8.74c0,0,0,0-0.01,0s0,0,0-0.01V8.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.32l0.08-0.08V8.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01V8.93z"/>
      <path class="st5" d="M9.14,8.3c0-0.01,0-0.02-0.01-0.03C9.12,8.26,9.11,8.26,9.1,8.26s-0.02,0-0.03,0.01C9.07,8.28,9.06,8.29,9.06,8.3v0.54c0,0.01,0,0.02,0.01,0.03C9.08,8.87,9.09,8.87,9.1,8.87s0.02,0,0.03-0.01s0.01-0.02,0.01-0.03V8.73c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.05c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.11c0,0.01,0,0.03-0.01,0.04C9.19,8.89,9.18,8.9,9.17,8.91C9.16,8.92,9.15,8.92,9.14,8.93C9.13,8.93,9.11,8.94,9.1,8.94c-0.01,0-0.03,0-0.04-0.01c-0.01,0-0.02-0.01-0.03-0.02S9.01,8.89,9.01,8.88S9,8.85,9,8.84V8.3c0-0.01,0-0.03,0.01-0.04c0.01-0.01,0.01-0.02,0.02-0.03C9.04,8.22,9.05,8.21,9.06,8.2C9.07,8.2,9.09,8.2,9.1,8.2c0.01,0,0.03,0,0.04,0.01c0.01,0.01,0.02,0.01,0.03,0.02c0.01,0.01,0.02,0.02,0.02,0.03C9.2,8.27,9.2,8.28,9.2,8.3v0.07c0,0,0,0,0,0.01s0,0-0.01,0H9.14c0,0,0,0-0.01,0c0,0,0,0,0-0.01V8.3z"/>
      <path class="st5" d="M9.44,8.25C9.44,8.25,9.44,8.25,9.44,8.25C9.43,8.26,9.43,8.26,9.43,8.26H9.37v0.67c0,0,0,0,0,0.01c0,0,0,0-0.01,0H9.31c0,0,0,0-0.01,0c0,0,0,0,0-0.01V8.26H9.25c0,0,0,0-0.01,0s0,0,0-0.01V8.2c0,0,0,0,0-0.01s0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01V8.25z"/>
      <path class="st5" d="M9.57,8.19c0.01,0,0.03,0,0.04,0.01c0.01,0.01,0.02,0.01,0.03,0.02s0.02,0.02,0.02,0.03c0.01,0.01,0.01,0.03,0.01,0.04v0.63c0,0.01,0,0.01-0.01,0.01H9.62c0,0,0,0-0.01,0s0,0,0-0.01V8.69H9.54v0.23c0,0,0,0,0,0.01s0,0-0.01,0H9.48c0,0,0,0-0.01,0s0,0,0-0.01V8.29c0-0.01,0-0.03,0.01-0.04S9.49,8.23,9.5,8.22S9.52,8.21,9.53,8.2C9.55,8.2,9.56,8.19,9.57,8.19z M9.61,8.29c0-0.01,0-0.02-0.01-0.03S9.58,8.26,9.57,8.26s-0.02,0-0.03,0.01C9.54,8.28,9.54,8.28,9.54,8.29v0.34h0.07V8.29z"/>
      <path class="st5" d="M7.22,10.93C7.22,10.93,7.22,10.93,7.22,10.93C7.21,10.94,7.21,10.94,7.22,10.93l-0.05,0.01c0,0,0,0-0.01,0s0,0,0-0.01v-0.67H7.08v0.67c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H7.03c0,0,0,0-0.01,0s0,0,0-0.01l0-0.68V10.2v0l0,0c0,0,0,0,0,0c0,0,0,0,0.01,0h0.18c0,0,0,0,0.01,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0.05V10.93z"/>
      <path class="st5" d="M7.35,10.26v0.19h0.1c0,0,0.01,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0h-0.1v0.36h0.13c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.05c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H7.29c0,0,0,0-0.01,0s0,0,0-0.01V10.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.05c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H7.35z"/>
      <path class="st5" d="M7.73,10.93C7.73,10.93,7.73,10.93,7.73,10.93C7.73,10.94,7.73,10.94,7.73,10.93l-0.05,0.01c-0.01,0-0.01,0-0.01-0.01v-0.2c-0.01,0-0.02,0.01-0.04,0.01s-0.03,0-0.04-0.01c-0.01,0-0.02-0.01-0.03-0.02c-0.01-0.01-0.02-0.02-0.02-0.03c0-0.01-0.01-0.02-0.01-0.04v-0.42c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01l0,0.42c0,0.01,0,0.02,0.01,0.02s0.02,0.01,0.03,0.01c0.01,0,0.02,0,0.02-0.01c0.01-0.01,0.01-0.01,0.01-0.02v-0.42c0,0,0-0.01,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01V10.93z"/>
      <path class="st5" d="M7.9,10.19c0.01,0,0.03,0,0.04,0.01c0.01,0.01,0.02,0.01,0.03,0.02s0.02,0.02,0.02,0.03C8,10.27,8,10.28,8,10.29v0.63c0,0.01,0,0.01-0.01,0.01H7.94c0,0,0,0-0.01,0s0,0,0-0.01v-0.23H7.86v0.23c0,0,0,0,0,0.01s0,0-0.01,0H7.81c0,0,0,0-0.01,0s0,0,0-0.01v-0.63c0-0.01,0-0.03,0.01-0.04s0.01-0.02,0.02-0.03s0.02-0.02,0.03-0.02C7.87,10.2,7.89,10.19,7.9,10.19z M7.93,10.29c0-0.01,0-0.02-0.01-0.03s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.02-0.01,0.03v0.34h0.07V10.29z"/>
      <path class="st5" d="M8.24,10.25C8.24,10.25,8.24,10.25,8.24,10.25c-0.01,0.01-0.01,0.01-0.01,0.01H8.17v0.67c0,0,0,0,0,0.01c0,0,0,0-0.01,0H8.12c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.67H8.05c0,0,0,0-0.01,0s0,0,0-0.01V10.2c0,0,0,0,0-0.01s0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01V10.25z"/>
      <path class="st5" d="M8.38,10.19c0.01,0,0.03,0,0.04,0.01c0.01,0.01,0.02,0.01,0.03,0.02s0.02,0.02,0.02,0.03c0.01,0.01,0.01,0.03,0.01,0.04v0.63c0,0.01,0,0.01-0.01,0.01H8.42c0,0,0,0-0.01,0s0,0,0-0.01v-0.23H8.34v0.23c0,0,0,0,0,0.01s0,0-0.01,0H8.29c0,0,0,0-0.01,0s0,0,0-0.01v-0.63c0-0.01,0-0.03,0.01-0.04s0.01-0.02,0.02-0.03s0.02-0.02,0.03-0.02C8.35,10.2,8.36,10.19,8.38,10.19z M8.41,10.29c0-0.01,0-0.02-0.01-0.03s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.02-0.01,0.03v0.34h0.07V10.29z"/>
      <path class="st5" d="M8.54,10.08L8.54,10.08C8.54,10.08,8.54,10.08,8.54,10.08l0-0.05c0,0,0,0,0-0.01c0,0,0,0,0,0l0.1,0.04l0.09-0.04c0,0,0,0,0,0c0,0,0,0,0,0.01v0.04c0,0,0,0.01,0,0.01h0l-0.09,0.04L8.54,10.08z M8.74,10.93C8.74,10.93,8.74,10.93,8.74,10.93C8.73,10.94,8.73,10.94,8.74,10.93l-0.05,0.01c0,0,0,0-0.01,0s0,0,0-0.01v-0.4L8.6,10.61v0.32c0,0,0,0,0,0.01c0,0,0,0-0.01,0H8.55c0,0,0,0-0.01,0s0,0,0-0.01V10.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.32l0.08-0.08V10.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01V10.93z"/>
      <path class="st5" d="M8.98,10.25C8.98,10.25,8.98,10.25,8.98,10.25c-0.01,0.01-0.01,0.01-0.01,0.01H8.91v0.67c0,0,0,0,0,0.01c0,0,0,0-0.01,0H8.86c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.67H8.79c0,0,0,0-0.01,0s0,0,0-0.01V10.2c0,0,0,0,0-0.01s0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01V10.25z"/>
      <path class="st5" d="M9.08,10.26v0.19h0.1c0,0,0.01,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0h-0.1v0.36h0.13c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.05c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H9.03c0,0,0,0-0.01,0s0,0,0-0.01V10.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.05c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H9.08z"/>
      <path class="st5" d="M9.95,10.93C9.95,10.93,9.94,10.93,9.95,10.93C9.94,10.94,9.94,10.94,9.95,10.93l-0.05,0.01c0,0-0.01,0-0.01,0c0,0,0,0,0-0.01V10.7H9.81v0.23c0,0,0,0,0,0.01c0,0,0,0-0.01,0H9.76c0,0,0,0-0.01,0c0,0,0,0,0-0.01V10.2c0,0,0,0,0-0.01s0,0,0.01,0H9.8c0,0,0,0,0.01,0s0,0,0,0.01v0.43h0.07V10.2c0-0.01,0-0.01,0.01-0.01h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01V10.93z"/>
      <path class="st5" d="M10.11,10.19c0.01,0,0.03,0,0.04,0.01c0.01,0.01,0.02,0.01,0.03,0.02s0.02,0.02,0.02,0.03c0.01,0.01,0.01,0.03,0.01,0.04v0.63c0,0.01,0,0.01-0.01,0.01h-0.04c0,0,0,0-0.01,0s0,0,0-0.01v-0.23h-0.07v0.23c0,0,0,0,0,0.01s0,0-0.01,0h-0.04c0,0,0,0-0.01,0s0,0,0-0.01v-0.63c0-0.01,0-0.03,0.01-0.04s0.01-0.02,0.02-0.03s0.02-0.02,0.03-0.02C10.08,10.2,10.1,10.19,10.11,10.19z M10.15,10.29c0-0.01,0-0.02-0.01-0.03s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.02-0.01,0.03v0.34h0.07V10.29z"/>
      <path class="st5" d="M11.09,10.93C11.09,10.93,11.09,10.93,11.09,10.93C11.08,10.94,11.08,10.94,11.09,10.93l-0.05,0.01c0,0-0.01,0-0.01,0c0,0,0,0,0-0.01v-0.52l-0.03,0.07c0,0.01-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02-0.01,0.03s-0.01,0.02-0.01,0.02l0,0.01l0,0c0,0,0,0-0.01,0H10.9c0,0,0,0-0.01,0l0,0c0,0,0,0,0,0l0-0.01c0-0.01,0-0.01-0.01-0.02c0-0.01-0.01-0.02-0.01-0.03s-0.01-0.02-0.01-0.03c0-0.01-0.01-0.02-0.01-0.03l-0.03-0.07v0.52c0,0,0,0,0,0.01c0,0,0,0-0.01,0h-0.04c0,0-0.01,0-0.01,0c0,0,0,0,0-0.01V10.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.05c0,0,0,0,0,0s0,0,0,0.01c0,0,0,0,0,0.01s0.01,0.02,0.01,0.03c0,0.01,0.01,0.03,0.02,0.05c0.01,0.02,0.01,0.04,0.02,0.05s0.01,0.04,0.02,0.06s0.01,0.03,0.02,0.05c0,0.01,0.01,0.03,0.01,0.04c0,0.01,0,0.02,0,0.02c0,0,0-0.01,0-0.02c0-0.01,0.01-0.02,0.01-0.04s0.01-0.03,0.02-0.05c0.01-0.02,0.01-0.04,0.02-0.05c0.01-0.02,0.01-0.04,0.02-0.05c0.01-0.02,0.01-0.03,0.02-0.05c0-0.01,0.01-0.03,0.01-0.03c0-0.01,0-0.01,0-0.01c0,0,0,0,0-0.01s0,0,0,0h0.05c0,0,0,0,0.01,0c0,0,0,0,0,0c0,0,0,0,0,0V10.93z"/>
      <path class="st5" d="M11.35,10.93C11.35,10.93,11.35,10.93,11.35,10.93C11.35,10.94,11.34,10.94,11.35,10.93l-0.05,0.01c0,0-0.01,0-0.01,0c0,0,0,0,0-0.01V10.7h-0.07v0.23c0,0,0,0,0,0.01c0,0,0,0-0.01,0h-0.04c0,0,0,0-0.01,0c0,0,0,0,0-0.01V10.2c0,0,0,0,0-0.01s0,0,0.01,0h0.04c0,0,0,0,0.01,0s0,0,0,0.01v0.43h0.07V10.2c0-0.01,0-0.01,0.01-0.01h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01V10.93z"/>
      <path class="st5" d="M11.48,10.26v0.19h0.1c0,0,0.01,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0h-0.1v0.36h0.13c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.05c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0h-0.18c0,0,0,0-0.01,0s0,0,0-0.01V10.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.05c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H11.48z"/>
      <path class="st5" d="M12.35,10.93C12.34,10.93,12.34,10.93,12.35,10.93C12.34,10.94,12.34,10.94,12.35,10.93l-0.05,0.01c-0.01,0-0.01,0-0.01-0.01v-0.2c-0.01,0-0.02,0.01-0.04,0.01s-0.03,0-0.04-0.01c-0.01,0-0.02-0.01-0.03-0.02c-0.01-0.01-0.02-0.02-0.02-0.03c0-0.01-0.01-0.02-0.01-0.04v-0.42c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01l0,0.42c0,0.01,0,0.02,0.01,0.02s0.02,0.01,0.03,0.01c0.01,0,0.02,0,0.02-0.01c0.01-0.01,0.01-0.01,0.01-0.02v-0.42c0,0,0-0.01,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01V10.93z"/>
      <path class="st5" d="M12.59,10.25C12.59,10.25,12.59,10.25,12.59,10.25c-0.01,0.01-0.01,0.01-0.01,0.01h-0.06v0.67c0,0,0,0,0,0.01c0,0,0,0-0.01,0h-0.04c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.67H12.4c0,0,0,0-0.01,0s0,0,0-0.01V10.2c0,0,0,0,0-0.01s0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01V10.25z"/>
      <path class="st5" d="M12.8,10.23c0,0,0.01,0.01,0.01,0.01c0,0,0,0.01,0.01,0.02c0,0.01,0,0.02,0,0.03v0.54c0,0.01,0,0.02,0,0.03c0,0.01,0,0.01-0.01,0.02c0,0,0,0.01-0.01,0.01c-0.01,0.01-0.02,0.02-0.03,0.03c-0.01,0.01-0.03,0.01-0.04,0.01c-0.01,0-0.03,0-0.04-0.01c-0.01-0.01-0.03-0.02-0.03-0.03c-0.01-0.01-0.01-0.02-0.02-0.03c0-0.01-0.01-0.02-0.01-0.03v-0.54c0-0.01,0-0.02,0.01-0.03s0.01-0.02,0.02-0.03c0.01-0.01,0.02-0.02,0.03-0.03c0.01-0.01,0.03-0.01,0.04-0.01c0.02,0,0.03,0,0.04,0.01S12.8,10.22,12.8,10.23z M12.76,10.29c0-0.01,0-0.02-0.01-0.02s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.02-0.01,0.02v0.54c0,0.01,0,0.02,0.01,0.02c0.01,0.01,0.02,0.01,0.03,0.01s0.02,0,0.03-0.01s0.01-0.02,0.01-0.02V10.29z"/>
      <path class="st5" d="M7.22,12.42c0,0.01,0,0.03-0.01,0.04c0,0.01-0.01,0.02-0.02,0.03c0.01,0.01,0.01,0.02,0.02,0.03c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02v0.28c0,0.02-0.01,0.04-0.02,0.06c-0.01,0.02-0.03,0.03-0.05,0.04c-0.01,0-0.02,0-0.03,0.01H7.12H7.07H7.03c0,0,0,0-0.01,0s0,0,0-0.01v-0.72c0,0,0,0,0-0.01s0,0,0.01,0h0.09h0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0l0.01,0c0.02,0.01,0.03,0.02,0.05,0.04c0.01,0.02,0.02,0.04,0.02,0.06V12.42z M7.08,12.46h0.04c0.01,0,0.02,0,0.03-0.01c0.01-0.01,0.01-0.01,0.01-0.03v-0.13c0-0.01,0-0.02-0.01-0.02c-0.01-0.01-0.02-0.01-0.03-0.01H7.08V12.46z M7.15,12.56c0-0.01,0-0.02-0.01-0.02c-0.01-0.01-0.02-0.01-0.03-0.01H7.08v0.35h0.04c0.01,0,0.02,0,0.03-0.01c0.01-0.01,0.01-0.02,0.01-0.02V12.56z"/>
      <path class="st5" d="M7.38,12.19c0.01,0,0.03,0,0.04,0.01c0.01,0.01,0.02,0.01,0.03,0.02s0.02,0.02,0.02,0.03c0.01,0.01,0.01,0.03,0.01,0.04v0.63c0,0.01,0,0.01-0.01,0.01H7.43c0,0,0,0-0.01,0s0,0,0-0.01V12.7H7.35v0.23c0,0,0,0,0,0.01s0,0-0.01,0H7.29c0,0,0,0-0.01,0s0,0,0-0.01v-0.63c0-0.01,0-0.03,0.01-0.04c0-0.01,0.01-0.02,0.02-0.03s0.02-0.02,0.03-0.02C7.36,12.2,7.37,12.19,7.38,12.19z M7.42,12.29c0-0.01,0-0.02-0.01-0.03s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.01-0.01,0.03v0.34h0.07V12.29z"/>
      <path class="st5" d="M7.88,12.93C7.88,12.93,7.88,12.93,7.88,12.93C7.87,12.94,7.87,12.94,7.88,12.93l-0.05,0.01c0,0-0.01,0-0.01,0c0,0,0,0,0-0.01v-0.52l-0.03,0.07c0,0.01-0.01,0.02-0.01,0.03s-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02-0.01,0.03s-0.01,0.02-0.01,0.02l0,0.01l0,0c0,0,0,0-0.01,0H7.69c0,0,0,0-0.01,0l0,0v0l0-0.01c0-0.01,0-0.01-0.01-0.02c0-0.01-0.01-0.02-0.01-0.03c0-0.01-0.01-0.02-0.01-0.03s-0.01-0.02-0.01-0.03l-0.03-0.07v0.52c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0H7.55c0,0-0.01,0-0.01,0c0,0,0,0,0-0.01v-0.72c0,0,0,0,0-0.01c0,0,0,0,0.01,0H7.6c0,0,0,0,0,0c0,0,0,0,0,0.01c0,0,0,0,0,0.01s0.01,0.02,0.01,0.03s0.01,0.03,0.02,0.05c0.01,0.02,0.01,0.04,0.02,0.05s0.01,0.04,0.02,0.05s0.01,0.03,0.02,0.05c0,0.01,0.01,0.03,0.01,0.04c0,0.01,0,0.01,0,0.02c0,0,0-0.01,0-0.02c0-0.01,0.01-0.02,0.01-0.04c0-0.01,0.01-0.03,0.02-0.05c0.01-0.02,0.01-0.04,0.02-0.05c0.01-0.02,0.01-0.04,0.02-0.05s0.01-0.03,0.02-0.05c0-0.01,0.01-0.02,0.01-0.03s0-0.01,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0,0h0.05c0,0,0,0,0.01,0c0,0,0,0,0,0c0,0,0,0,0,0V12.93z"/>
      <path class="st5" d="M8.61,12.25C8.61,12.25,8.61,12.25,8.61,12.25C8.6,12.26,8.6,12.26,8.6,12.26H8.54v0.67c0,0,0,0,0,0.01c0,0,0,0-0.01,0H8.48c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.67H8.42c0,0,0,0-0.01,0s0,0,0-0.01v-0.04c0,0,0,0,0-0.01s0,0,0.01,0H8.6c0,0,0,0,0.01,0c0,0,0,0,0,0.01V12.25z"/>
      <path class="st5" d="M8.82,12.23c0,0,0.01,0.01,0.01,0.01c0,0,0,0.01,0.01,0.02c0,0.01,0,0.02,0,0.03v0.54c0,0.01,0,0.02,0,0.03c0,0,0,0.01-0.01,0.01c0,0,0,0.01-0.01,0.01c-0.01,0.01-0.02,0.02-0.03,0.03c-0.01,0.01-0.03,0.01-0.04,0.01c-0.01,0-0.03,0-0.04-0.01c-0.01-0.01-0.03-0.02-0.03-0.03c-0.01-0.01-0.01-0.02-0.02-0.03c0-0.01-0.01-0.02-0.01-0.03v-0.54c0-0.01,0-0.02,0.01-0.03s0.01-0.02,0.02-0.03c0.01-0.01,0.02-0.02,0.03-0.03c0.01-0.01,0.03-0.01,0.04-0.01c0.02,0,0.03,0,0.04,0.01S8.81,12.22,8.82,12.23z M8.78,12.29c0-0.01,0-0.02-0.01-0.02s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.01-0.01,0.02v0.54c0,0.01,0,0.02,0.01,0.02c0.01,0.01,0.02,0.01,0.03,0.01s0.02,0,0.03-0.01s0.01-0.02,0.01-0.02V12.29z"/>
      <path class="st5" d="M9.11,12.93C9.11,12.93,9.11,12.93,9.11,12.93C9.1,12.94,9.1,12.94,9.11,12.93l-0.05,0.01c0,0,0,0-0.01,0s0,0,0-0.01v-0.67H8.97v0.58c0,0.01,0,0.01,0,0.02c0,0.01,0,0.01,0,0.02c0,0.01-0.01,0.02-0.02,0.03c-0.01,0.01-0.02,0.02-0.04,0.02c-0.01,0-0.02,0.01-0.03,0.01h0c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.04c0-0.01,0-0.01,0.01-0.01c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01-0.01c0.01-0.01,0.01-0.02,0.01-0.03v-0.59v-0.04c0,0,0,0,0,0l0,0c0,0,0,0,0.01,0H9.1c0,0,0,0,0.01,0c0,0,0,0,0,0.01V12.93z"/>
      <path class="st5" d="M9.29,12.93C9.29,12.94,9.29,12.94,9.29,12.93c-0.01,0-0.01,0-0.02,0h0H9.18c0,0,0,0-0.01,0c0,0,0,0,0-0.01V12.5v-0.29c0,0,0,0,0-0.01s0,0,0.01,0h0.04c0,0,0,0,0.01,0s0,0,0,0.01v0.21h0.04c0,0,0.01,0,0.01,0c0.01,0,0.02,0,0.03,0.01c0.02,0.01,0.03,0.02,0.05,0.04c0.01,0.02,0.02,0.04,0.02,0.06v0.33c0,0.02-0.01,0.04-0.02,0.06s-0.03,0.03-0.05,0.04C9.3,12.93,9.3,12.93,9.29,12.93C9.3,12.93,9.29,12.93,9.29,12.93z M9.31,12.51c0-0.01,0-0.02-0.01-0.03s-0.02-0.01-0.03-0.01H9.24v0.4h0.04c0.01,0,0.02,0,0.03-0.01s0.01-0.01,0.01-0.02V12.51z"/>
      <path class="st5" d="M9.64,12.42c0,0.01,0,0.03-0.01,0.04c0,0.01-0.01,0.02-0.02,0.03c0.01,0.01,0.01,0.02,0.02,0.03c0,0.01,0.01,0.02,0.01,0.04v0.37c0,0,0,0,0,0.01c0,0,0,0-0.01,0H9.58c-0.01,0-0.01,0-0.01-0.01v-0.37v0c0-0.01,0-0.02-0.01-0.02c-0.01-0.01-0.01-0.01-0.02-0.01H9.5v0.41c0,0,0,0,0,0.01c0,0,0,0-0.01,0H9.45c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.72c0,0,0,0,0-0.01s0,0,0.01,0h0.05c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.25h0.04c0.01,0,0.02,0,0.02-0.01c0.01-0.01,0.01-0.01,0.01-0.02v0V12.2c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01V12.42z"/>
      <path class="st5" d="M9.88,12.23c0,0,0.01,0.01,0.01,0.01c0,0,0,0.01,0.01,0.02c0,0.01,0,0.02,0,0.03v0.54c0,0.01,0,0.02,0,0.03c0,0,0,0.01-0.01,0.01c0,0,0,0.01-0.01,0.01c-0.01,0.01-0.02,0.02-0.03,0.03c-0.01,0.01-0.03,0.01-0.04,0.01c-0.01,0-0.03,0-0.04-0.01c-0.01-0.01-0.03-0.02-0.03-0.03c-0.01-0.01-0.01-0.02-0.02-0.03c0-0.01-0.01-0.02-0.01-0.03v-0.54c0-0.01,0-0.02,0.01-0.03s0.01-0.02,0.02-0.03c0.01-0.01,0.02-0.02,0.03-0.03c0.01-0.01,0.03-0.01,0.04-0.01c0.02,0,0.03,0,0.04,0.01S9.87,12.22,9.88,12.23z M9.84,12.29c0-0.01,0-0.02-0.01-0.02s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.01-0.01,0.02v0.54c0,0.01,0,0.02,0.01,0.02c0.01,0.01,0.02,0.01,0.03,0.01s0.02,0,0.03-0.01s0.01-0.02,0.01-0.02V12.29z"/>
      <path class="st5" d="M10.65,12.93C10.65,12.93,10.64,12.93,10.65,12.93C10.64,12.94,10.64,12.94,10.65,12.93l-0.05,0.01c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.67h-0.07v0.67c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0h-0.05c0,0,0,0-0.01,0c0,0,0,0,0-0.01l0-0.68v-0.04v0l0,0c0,0,0,0,0,0c0,0,0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0v0.04V12.93z"/>
      <path class="st5" d="M10.89,12.23c0,0,0.01,0.01,0.01,0.01c0,0,0,0.01,0.01,0.02c0,0.01,0,0.02,0,0.03v0.54c0,0.01,0,0.02,0,0.03c0,0,0,0.01-0.01,0.01c0,0,0,0.01-0.01,0.01c-0.01,0.01-0.02,0.02-0.03,0.03c-0.01,0.01-0.03,0.01-0.04,0.01c-0.01,0-0.03,0-0.04-0.01c-0.01-0.01-0.03-0.02-0.03-0.03c-0.01-0.01-0.01-0.02-0.02-0.03c0-0.01-0.01-0.02-0.01-0.03v-0.54c0-0.01,0-0.02,0.01-0.03s0.01-0.02,0.02-0.03c0.01-0.01,0.02-0.02,0.03-0.03c0.01-0.01,0.03-0.01,0.04-0.01c0.02,0,0.03,0,0.04,0.01S10.88,12.22,10.89,12.23z M10.85,12.29c0-0.01,0-0.02-0.01-0.02s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.01-0.01,0.02v0.54c0,0.01,0,0.02,0.01,0.02c0.01,0.01,0.02,0.01,0.03,0.01s0.02,0,0.03-0.01s0.01-0.02,0.01-0.02V12.29z"/>
      <path class="st5" d="M11.16,12.25C11.16,12.25,11.16,12.25,11.16,12.25c-0.01,0.01-0.01,0.01-0.01,0.01h-0.06v0.67c0,0,0,0,0,0.01c0,0,0,0-0.01,0h-0.04c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.67h-0.06c0,0,0,0-0.01,0s0,0,0-0.01v-0.04c0,0,0,0,0-0.01s0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01V12.25z"/>
      <path class="st5" d="M11.33,12.2c0.02,0.01,0.03,0.02,0.05,0.04c0.01,0.02,0.02,0.04,0.02,0.06v0.33c0,0.02-0.01,0.04-0.02,0.06c-0.01,0.02-0.03,0.03-0.05,0.04c0,0-0.01,0-0.01,0s-0.01,0-0.02,0c0,0,0,0-0.01,0h-0.04v0.21c0,0,0,0,0,0.01s0,0-0.01,0H11.2c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.29v0v-0.43c0,0,0,0,0-0.01s0,0,0.01,0h0.09h0c0,0,0,0,0.01,0c0,0,0,0,0.01,0L11.33,12.2zM11.33,12.29c0-0.01,0-0.02-0.01-0.03s-0.02-0.01-0.03-0.01h-0.04v0.4h0.04c0.01,0,0.02,0,0.03-0.01s0.01-0.01,0.01-0.03V12.29z"/>
      <path class="st5" d="M11.52,12.26v0.19h0.1c0,0,0.01,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0,0,0.01c0,0,0,0-0.01,0h-0.1v0.36h0.13c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0h-0.18c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.72c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0,0,0.01c0,0,0,0-0.01,0H11.52z"/>
      <path class="st5" d="M11.89,12.52c0,0.01,0.01,0.02,0.01,0.04v0.28c0,0.02-0.01,0.04-0.02,0.06c-0.01,0.02-0.03,0.03-0.05,0.04c-0.01,0-0.02,0.01-0.03,0.01h-0.1c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.72c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.16v0.06h-0.1v0.2h0.04c0,0,0,0,0.01,0c0.01,0,0.01,0,0.02,0s0.02,0.01,0.02,0.01c0.01,0,0.02,0.01,0.02,0.02l0,0c0,0,0,0,0,0C11.88,12.5,11.89,12.51,11.89,12.52z M11.83,12.55c0-0.01,0-0.02-0.01-0.02s-0.01-0.01-0.03-0.01h-0.04v0.35h0.04c0.01,0,0.02,0,0.03-0.01s0.01-0.01,0.01-0.02V12.55z"/>
      <path class="st5" d="M12.17,12.84c0,0.01,0,0.02-0.01,0.04c0,0.01-0.01,0.01-0.01,0.02c0,0.01-0.01,0.01-0.01,0.02c-0.01,0.01-0.02,0.02-0.04,0.02c-0.01,0-0.02,0.01-0.03,0.01h-0.04c0,0,0,0,0,0c0,0,0,0,0-0.01v-0.04c0-0.01,0-0.01,0.01-0.01h0.04c0.01,0,0.02,0,0.02-0.01c0.01-0.01,0.01-0.02,0.01-0.03v-0.12c-0.01,0-0.02,0.01-0.04,0.01s-0.03,0-0.04-0.01c-0.01,0-0.01-0.01-0.02-0.01c0,0-0.01-0.01-0.01-0.01c0,0-0.01-0.01-0.01-0.01c0-0.01-0.01-0.01-0.01-0.02c-0.01-0.01-0.01-0.03-0.01-0.04V12.2c0,0,0,0,0-0.01s0,0,0.01,0h0.04c0,0,0,0,0.01,0c0,0,0,0,0,0.01l0,0.42c0,0.01,0,0.02,0.01,0.03s0.02,0.01,0.03,0.01c0.01,0,0.02,0,0.03-0.01s0.01-0.02,0.01-0.02v-0.42c0,0,0,0,0-0.01s0,0,0.01,0h0.04c0,0,0.01,0,0.01,0c0,0,0,0,0,0.01V12.84z"/>
      <path class="st5" d="M12.3,12.26v0.19h0.1c0,0,0.01,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0,0,0.01c0,0,0,0-0.01,0h-0.1v0.36h0.13c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0.01,0,0.01c0,0,0,0-0.01,0h-0.18c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.72c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.04c0,0,0,0,0,0.01c0,0,0,0-0.01,0H12.3z"/>
      <path class="st5" d="M12.66,12.25C12.66,12.25,12.66,12.25,12.66,12.25c-0.01,0.01-0.01,0.01-0.01,0.01H12.6v0.67c0,0,0,0,0,0.01c0,0,0,0-0.01,0h-0.04c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.67h-0.06c0,0,0,0-0.01,0s0,0,0-0.01v-0.04c0,0,0,0,0-0.01s0,0,0.01,0h0.18c0,0,0,0,0.01,0c0,0,0,0,0,0.01V12.25z"/>
      <path class="st5" d="M12.83,12.3c0-0.01,0-0.02-0.01-0.03s-0.02-0.01-0.03-0.01s-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.02-0.01,0.03v0.54c0,0.01,0,0.02,0.01,0.02c0.01,0.01,0.02,0.01,0.03,0.01s0.02,0,0.03-0.01s0.01-0.01,0.01-0.02v-0.11c0,0,0,0,0-0.01c0,0,0,0,0.01,0h0.05c0,0,0,0,0.01,0c0,0,0,0,0,0.01v0.11c0,0.01,0,0.03-0.01,0.04c-0.01,0.01-0.01,0.02-0.02,0.03c-0.01,0.01-0.02,0.02-0.03,0.02c-0.01,0-0.02,0.01-0.04,0.01c-0.01,0-0.03,0-0.04-0.01c-0.01,0-0.02-0.01-0.03-0.02c-0.01-0.01-0.02-0.02-0.02-0.03c0-0.01-0.01-0.03-0.01-0.04V12.3c0-0.01,0-0.03,0.01-0.04c0.01-0.01,0.01-0.02,0.02-0.03c0.01-0.01,0.02-0.02,0.03-0.02c0.01,0,0.03-0.01,0.04-0.01c0.01,0,0.03,0,0.04,0.01c0.01,0.01,0.02,0.01,0.03,0.02c0.01,0.01,0.02,0.02,0.02,0.03c0,0.01,0.01,0.03,0.01,0.04v0.07c0,0,0,0,0,0.01c0,0,0,0-0.01,0h-0.05c0,0,0,0-0.01,0c0,0,0,0,0-0.01V12.3z"/>
      <path class="st5" d="M13.15,12.93C13.15,12.93,13.15,12.93,13.15,12.93C13.15,12.94,13.15,12.94,13.15,12.93l-0.05,0.01c0,0,0,0-0.01,0s0,0,0-0.01V12.7h-0.04c-0.01,0-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.01-0.01,0.02v0.19c0,0,0,0,0,0.01s0,0-0.01,0h-0.05c0,0,0,0-0.01,0c0,0,0,0,0-0.01v-0.19c0-0.01,0-0.03,0.01-0.04c0-0.01,0-0.01,0.01-0.02c0,0,0.01-0.01,0.01-0.01c0,0-0.01-0.01-0.01-0.01c0,0-0.01-0.01-0.01-0.02c0-0.01-0.01-0.02-0.01-0.04v-0.31c0-0.02,0.01-0.04,0.02-0.06c0.01-0.02,0.03-0.03,0.05-0.04l0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0h0.1c0,0,0,0,0.01,0c0,0,0,0,0,0.01L13.15,12.93zM13.09,12.64v-0.38h-0.04c-0.01,0-0.02,0-0.03,0.01c-0.01,0.01-0.01,0.01-0.01,0.02v0.31c0,0.01,0,0.02,0.01,0.02c0.01,0.01,0.02,0.01,0.03,0.01H13.09z"/>
    </g>
    <g>
      <polygon class="st0" points="10,8.84 9.62,8.84 9.48,8.68 9.42,8.84 8.94,8.84 8.74,8.9 8.45,8.5 8.34,8.5 8.27,8.84 7.66,8.84 7.48,8.61 7.37,8.84 6.99,8.84 6.99,8.2 7.56,8.2 7.83,8.57 7.93,8.2 8.96,8.2 9.13,8.37 9.2,8.2 9.84,8.2 10,8.32"/>
      <polygon class="st0" points="12.98,10.86 12.36,10.86 12.06,10.54 11.93,10.86 11.45,10.86 11.26,10.92 11.09,10.68 11.01,10.86 9.88,10.86 9.65,10.58 9.56,10.7 9.58,10.86 8.99,10.86 8.99,10.21 10.05,10.21 10.32,10.59 10.36,10.47 10.42,10.21 11.47,10.21 11.64,10.39 11.71,10.21 12.98,10.21 12.98,10.34 12.83,10.47"/>
      <polygon class="st0" points="12.63,12.85 12.23,12.83 12.04,12.59 11.87,12.83 11.11,12.85 11.01,12.65 11.01,12.2 11.34,12.2 11.58,12.36 11.68,12.2 12.43,12.2 12.63,12.46"/>
    </g>
    <polygon class="st0" points="8.5,10.55 8.27,10.21 8.03,10.21 7.86,10.45 7.75,10.21 6.99,10.21 6.99,10.86 7.27,10.86 7.46,10.6 7.57,10.86 8.49,10.86"/>
    <polygon class="st0" points="10.51,12.85 9.73,12.85 9.54,12.9 9.37,12.67 9.28,12.85 8.62,12.85 8.4,12.57 8.32,12.85 6.99,12.85 6.99,12.2 7.41,12.2 7.69,12.58 7.8,12.53 7.79,12.2 9.53,12.2 9.69,12.15 9.92,12.38 9.99,12.2 10.51,12.2 10.51,12.53 10.6,12.71"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesPrinter'
}
</script>
