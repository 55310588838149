<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M16.19,0.89c-2.29,0-3.61,1.57-3.87,3.29L2.53,4.23L2.07,4.98l7.08,9.33l-0.12,4.63c-2.96,0.41-6.97,2.12,0.9,2.12c7.06,0,3.94-1.58,0.65-2.12l-0.15-4.67l6.71-9.46l-0.33-0.64l-3.41,0.02c0.25-1.17,1.18-2.19,2.75-2.19c3.61,0,3.64,5.43,0.22,5.59l-0.58,1.08c0.15,0.01,0.3,0.02,0.46,0.02C21.25,8.68,21.28,0.89,16.19,0.89z M15.48,5.25c0,0-3.82,5.68-4.27,6.28c-0.49,0.66-2.29,0.73-2.82,0.01c-0.52-0.72-4.67-6.19-4.67-6.19L15.48,5.25z"/>
    <path class="st0" d="M10.92,11.16c-0.45,0.67-1.76,0.69-2.35-0.11C8.1,10.42,5.4,6.77,5.4,6.77c3.48-0.93,4.35,1.17,8.33,0.16C13.73,6.94,11.27,10.63,10.92,11.16z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesAlcohol'
}
</script>

<style scoped>
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
</style>
