<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 10.5 17" style="enable-background:new 0 0 10.5 17;" xml:space="preserve">
    <g>
      <path class="st0" d="M0,9.7h1.5V2.1h3.3c1.7,0,3,0.3,3.9,1c1.2,0.8,1.8,2,1.8,3.6c0,0.6-0.1,1.2-0.4,1.8C9.9,9,9.6,9.5,9.2,9.9   c-0.5,0.5-1.1,0.9-1.8,1.2c-0.7,0.3-1.6,0.4-2.7,0.4H3.5v1.3h6.1v1.7H3.5V17h-2v-2.6H0v-1.7h1.5v-1.3H0V9.7z M8.4,6.6   c0-1-0.4-1.8-1.1-2.3C6.8,4,5.9,3.8,4.7,3.8H3.5v6h1.3c0.8,0,1-0.1,1.5-0.2c0.5-0.1,0.9-0.4,1.2-0.7C8.2,8.3,8.4,7.5,8.4,6.6z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconRuble'
}
</script>

<style scoped>

</style>
