<template lang="pug">

  div
    .td-fast-urgency(v-if="getUrgency(service.info.urgency.categories).length > 0 || (service.info.urgency.charges && service.info.urgency.charges[0] && service.info.urgency.charges[0]['charges'] && service.info.urgency.charges[0]['charges'][0] && service.info.urgency.charges[0]['charges'][0]['charge_value'] && service.info.urgency.charges[0]['charges'][0]['charge_value'] > 0)")
        .td-fast-urgency-item.mini
          .td-fast-urgency-item-title
            template(v-if="service.info.urgency.urgency[0].provider_urgency_type === 'midnight_gap' && ((terminalName).indexOf('Шереметьево') !== -1 || (terminalName).indexOf('Sheremetyevo') !== -1)") {{ `При оформлении после ${getTime(service.info.urgency.urgency[0].total_time)} предыдущего дня` }} <span>&mdash; </span><span class="link" @click="scrollTo('price')">срочный тариф:</span>

            template(v-else) {{ `При оформлении за ${service.info.urgency.urgency[0].total_time} ${getPlural(service.info.urgency.urgency[0].total_time)} до услуги` }} <span>&mdash;</span> <span class="link" @click="scrollTo('price')">наценка за срочность:</span>

            .td-fast-urgency-item-part(v-if="((terminalName).indexOf('Шереметьево') !== -1 || (terminalName).indexOf('Sheremetyevo') !== -1) && ['VIP-зал', 'Vip Lounge'].includes(service.name)")
              .td-fast-urgency-item-categories(v-for="urgency in getUrgency(service.info.urgency.categories, true, service.price)")
                span.icon-years.icon-baby(v-if="['Младенец', 'Infant'].includes(urgency.category_name)" title="Младенец")
                span.icon-years.icon-child(v-if="['Ребенок', 'Child'].includes(urgency.category_name)" title="Ребенок")
                span.icon-years.icon-adult(v-if="['Взрослый', 'Adult'].includes(urgency.category_name)" title="Взрослый")
                | <nobr v-html="thousandSeparator(urgency.charge_urgency) + ' ₽'"></nobr>

            .td-fast-urgency-item-part(v-else)
              .td-fast-urgency-item-categories(v-for="urgency in getUrgency(service.info.urgency.categories)")
                span.icon-years.icon-baby(v-if="['Младенец', 'Infant'].includes(urgency.category_name)" title="Младенец")
                span.icon-years.icon-child(v-if="['Ребенок', 'Child'].includes(urgency.category_name)" title="Ребенок")
                span.icon-years.icon-adult(v-if="['Взрослый', 'Adult'].includes(urgency.category_name)" title="Взрослый")
                | +<nobr v-html="thousandSeparator(urgency.charge_urgency) + ' ₽'"></nobr>

            .td-fast-urgency-item-part(v-if="service.info.urgency.charges.length > 0 && !(((terminalName).indexOf('Шереметьево') !== -1 || (terminalName).indexOf('Sheremetyevo') !== -1) && ['VIP-зал', 'Vip Lounge'].includes(service.name))")
              .td-fast-urgency-item-categories(v-for="urgency in service.info.urgency.charges[0].charges")
                | +{{ urgency.charge_value }}<span v-if="urgency.charge_currency"> <nobr v-html="thousandSeparator(urgency.charge_urgency)"></nobr></span><span v-if="!urgency.charge_currency">%</span>&nbsp;на весь заказ

</template>

<script>
export default {
  name: 'ServiceMoreUrgency',
  props: ['service', 'terminalName'],

  methods: {
    scrollTo (block) {
      document.getElementById(block).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    },
    getUrgencyHtml (urgency) {
      const thousandSeparator = this.thousandSeparator(urgency.charge_value)
      return `+<span v-if="${urgency.charge_currency}"><div v-html="${thousandSeparator}"></div></span><span v-if="!urgency.charge_currency">%</span>`
    },
    getUrgency (categories, svo) {
      const array = []
      categories.forEach((category) => {
        category.prices.forEach((price) => {
          if (price.charge_urgency !== '0.00') {
            if (array.length <= 0) {
              const item = {
                charge_urgency: svo ? (price.charge_urgency * 1) + (price.price * 1) : price.charge_urgency,
                charge_currency_urgency: price.charge_currency_urgency,
                category_name: category.name
              }
              array.unshift(item)
            } else {
              let finded = false
              array.forEach((part, index) => {
                if (part.category_name === category.name) {
                  finded = index
                }
              })
              if (finded === false) {
                const item = {
                  charge_urgency: svo ? (price.charge_urgency * 1) + (price.price * 1) : price.charge_urgency,
                  charge_currency_urgency: price.charge_currency_urgency,
                  category_name: category.name
                }
                array.unshift(item)
              }
            }
          }
        })
      })
      return array
    },

    getTime (number) {
      let response = ''
      number = number * 1
      if (number > 24) {
        response = 24 - (number - 24)
      } else {
        response = 24 - number
      }
      return response + ':00'
    },

    getPlural (nmb) {
      let response = ''

      const number = Math.abs(nmb)
      const mod10 = number % 10
      const mod100 = number % 100
      let form

      if (mod10 === 1 && mod100 !== 11) {
        form = 0
      } else if (mod10 >= 2 && mod10 <= 4 && !(mod100 > 10 && mod100 < 20)) {
        form = 1
      } else {
        form = 2
      }

      switch (form) {
        case 0: {
          response = 'час'
          break
        }
        case 1: {
          response = 'часа'
          break
        }
        case 2: {
          response = 'часов'
          break
        }
      }

      return response
    },

    thousandSeparator (value) {
      if (!value) return '0'

      value = value.toString()

      var parts = (value + '').split('.')
      var main = parts[0]
      var len = main.length
      var output = ''
      var i = len - 1

      while (i >= 0) {
        output = main.charAt(i) + output
        if ((len - i) % 3 === 0 && i > 0) {
          output = ' ' + output
        }
        --i
      }

      if (parts.length > 1) {
        output += '.' + parts[1]
      }

      return output.replace('.00', '')
    }

  }
}
</script>
<style lang="stylus">
.ServiceMore-main-block-container
  .td-fast-urgency
    margin-bottom: 1rem

.td-fast-urgency
  &-item
    display: flex
    flex-wrap: wrap

    &.mini
      .rub
        path
          fill: #fff

    .rub
      height: 11px

      path
        fill: #000

    &-title
      margin-right: 5px
      opacity: .8

      .link
        border-bottom: 1px dotted #fff
        text-decoration: none
        color: #fff
        cursor: pointer

        &:hover
          color: var(--secondary)
          border-bottom-color: var(--secondary)

    &-part
      display: inline-flex
      flex-wrap: wrap
      padding-left: 5px

    &-categories
      display: flex
      margin-right: 10px

      .icon-years
        display: flex
        position: relative
        top: 2px
        left: 0

        &.icon-adult
          flex: none
          width: 12px
          height: 15px
          margin-left: 0
          background-image: url('../assets/icon-adult.svg')
          background-position: 50%
          background-size: contain
          background-repeat: no-repeat

        &.icon-child
          flex: none
          width: 12px
          height: 15px
          margin-left: 0
          background-image: url('../assets/icon-child.svg')
          background-position: 50%
          background-size: contain
          background-repeat: no-repeat
          margin-right: 2px

        &.icon-baby
          flex: none
          top: 0
          width: 15px
          height: 15px
          margin-left: 0
          background-image: url('../assets/icon-baby.svg')
          background-position: 50%
          background-size: contain
          background-repeat: no-repeat
</style>
