<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M17.27,18.61c-0.49,1.88-1.12,2.73-2.54,2.36c-0.94-0.25-8.23-2.41-12.21-3.59c-1.43-0.42-2.3-1.4-1.97-2.61C0.89,13.52,3.6,3.49,3.6,3.49l14.34,3.93c0,0-2.79,9.65-3.2,11.37c-0.28,1.17,0.77,1.11,1.02,0.25c0.32-1.1,3.26-11.34,3.26-11.34l0.95,0.29C19.97,7.99,17.67,17.07,17.27,18.61z M4.45,5.11c0,0-2.25,8.43-2.48,9.23c-0.22,0.8,0.03,1.45,0.95,1.72c3.41,1.01,10.15,2.99,10.15,2.99l3.1-10.69L4.45,5.11z M11.41,9.65l0.21-0.85l2.57,0.66l-0.22,0.84L11.41,9.65z M12.16,17.1L8.3,16.02l0.18-0.7l3.87,1.08L12.16,17.1z M4.68,10.27L5.45,7.1l5.31,1.47l-0.87,3.21L4.68,10.27z M7.28,15.71l-3.64-1.14l0.16-0.68l3.67,1.13L7.28,15.71z M9.03,13.17l3.91,1.04l-0.2,0.73l-3.89-1.05L9.03,13.17z M4.28,11.82l3.91,1.04l-0.2,0.73L4.1,12.53L4.28,11.82z M11.01,11.21l2.54,0.65l-0.23,0.84l-2.53-0.66L11.01,11.21z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesPress'
}
</script>
