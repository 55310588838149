import IconSubservicesAlcohol from '@/components/icons/options/IconSubservicesAlcohol'
import IconSubservicesAssistance from '@/components/icons/options/IconSubservicesAssistance'
import IconSubservicesAssist from '@/components/icons/options/IconSubservicesAssist'
import IconSubservicesClock from '@/components/icons/options/IconSubservicesClock'
import IconSubservicesConversation from '@/components/icons/options/IconSubservicesConversation'
import IconSubservicesFood from '@/components/icons/options/IconSubservicesFood'
import IconSubservicesKids from '@/components/icons/options/IconSubservicesKids'
import IconSubservicesLounge from '@/components/icons/options/IconSubservicesLounge'
import IconSubservicesLuggage from '@/components/icons/options/IconSubservicesLuggage'
import IconSubservicesPassport from '@/components/icons/options/IconSubservicesPassport'
import IconSubservicesTransfer from '@/components/icons/options/IconSubservicesTransfer'
import IconSubservicesParking from '@/components/icons/options/IconSubservicesParking'
import IconSubservicesMisc from '@/components/icons/options/IconSubservicesMisc'
import IconSubservicesPrinter from '@/components/icons/options/IconSubservicesPrinter'
import IconSubservicesWash from '@/components/icons/options/IconSubservicesWash'
import IconSubservicesPress from "@/components/icons/options/IconSubservicesPress"
import IconSubservicesWifi from '@/components/icons/options/IconSubservicesWifi'
import IconSubservicesShop from '@/components/icons/options/IconSubservicesShop'
import IconTaxRefund from '@/components/icons/options/IconTaxRefund'
import IconSmokingAreas from '@/components/icons/options/IconSmokingAreas'
import IconNoSmokingAreas from '@/components/icons/options/IconNoSmokingAreas'
import IconDisabledAccess from '@/components/icons/options/IconDisabledAccess'
import IconAnimalsAllowed from '@/components/icons/options/IconAnimalsAllowed'

export default {
  lounge: IconSubservicesLounge, // 'Зал'
  clock: IconSubservicesClock,
  passport: IconSubservicesPassport,
  luggage: IconSubservicesLuggage, // 'Багаж'
  transfer: IconSubservicesTransfer,
  parking: IconSubservicesParking, // 'Парковка'
  alcohol: IconSubservicesAlcohol, // 'Алкоголь'
  food: IconSubservicesFood,
  assist: IconSubservicesAssist,
  comfort: IconSubservicesMisc, // 'Удобство'
  children: IconSubservicesKids, // 'Дети'
  'office-equipment': IconSubservicesPrinter, // 'Оргтехника'
  shower: IconSubservicesWash, // 'Душ'
  press: IconSubservicesPress, // 'Пресса'
  'the-internet-and-tv': IconSubservicesWifi, // 'Интернет/TV',
  'food-and-water': IconSubservicesFood, // 'Еда и вода'
  'dressing-room': IconSubservicesMisc, // 'Гардероб'
  'air-conditioner': IconSubservicesMisc,
  'tax-free': IconSubservicesShop, // 'Tax free'
  'meeting-room': IconSubservicesConversation, // 'Переговоры'
  escort: IconSubservicesAssistance, // 'Сопровождение'
  'access-to-the-hall': IconSubservicesLounge,
  control: IconSubservicesPassport,
  'inspection-and-control': IconSubservicesPassport, // 'Досмотр'
  has_tax_refund: IconTaxRefund, // Оформление Tax Refund
  has_smoking_areas: IconSmokingAreas, // Наличие мест для курения
  no_smoking_areas: IconNoSmokingAreas, // Наличие мест для курения
  has_disabled_access: IconDisabledAccess, // Доступность для лиц с ограниченными возможностями
  has_animals_allowed: IconAnimalsAllowed // Доступность для животных
}
