<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <polygon class="st0" points="6.11,21 6.19,21.95 4.85,21.96 4.86,20.96 "/>
    <polygon class="st0" points="15.18,21 15.26,22 13.92,21.96 13.92,20.96 "/>
    <path d="M14.87,6h-2.65V2.52h0.38c0.21,0,0.38-0.17,0.38-0.38V1.4c0-0.21-0.17-0.38-0.38-0.38H7.41c-0.21,0-0.38,0.17-0.38,0.38v0.75c0,0.21,0.17,0.38,0.38,0.38h0.38V6H5.14C4.51,6,4,6.51,4,7.14v12.51c0,0.63,0.51,0.75,1.14,0.75h9.74c0.63,0,1.14-0.12,1.14-0.75V7.14C16.01,6.51,15.5,6,14.87,6z M8.55,2.52h2.92V6H8.55V2.52z M15.25,19.26c0,0.21-0.17,0.38-0.38,0.38H5.14c-0.21,0-0.38-0.17-0.38-0.38V7.14c0-0.21,0.17-0.38,0.38-0.38h9.74c0.21,0,0.38,0.17,0.38,0.38V19.26z"/>
    <path d="M14.87,6.38H5.14c-0.42,0-0.76,0.34-0.76,0.76v12.12c0,0.42,0.34,0.76,0.76,0.76h9.74c0.42,0,0.76-0.34,0.76-0.76V7.14C15.63,6.72,15.29,6.38,14.87,6.38z M7.41,18.12H6.65V8.27h0.76V18.12z M10.32,18.12H9.56v-5.48l0.76,0.2V18.12z M13.36,18.12H12.6v-4.66l0.76,0.2V18.12z M13.96,11.49l-0.34-0.09l-0.4-0.7c-0.22-0.06-0.57-0.15-0.9-0.24l0.05,2.2l-0.51-0.14l-0.73-2.38c-0.21-0.06-2.19-0.59-2.19-0.59C8.72,9.5,8.58,9.3,8.63,9.11c0.05-0.19,0.27-0.29,0.5-0.23c0,0,1.83,0.49,2.19,0.59l1.82-1.7l0.51,0.14l-1.14,1.88c0.33,0.09,0.68,0.18,0.9,0.24l0.69-0.41l0.34,0.09l-0.54,0.81L13.96,11.49z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesLuggage'
}
</script>

<style scoped>
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
</style>
