<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M10.26,21.43c-11.94,0-11.4-17.06-0.19-17.06C21.23,4.38,21.17,21.43,10.26,21.43z M10.05,5.52  c-0.54,0-1.08,0.05-1.61,0.15l0.2,1.17L6.2,8.58L4.04,8.45c-0.21,0.3-0.4,0.61-0.57,0.94l0.45,4.23l1.33,1.71l-1.36,1.93  c1.22,1.78,3.31,3.04,6.32,3.04c4.34,0,6.69-3.12,7.04-6.49l-1.02-0.93l-1.49-0.57l-0.24,0.37l0.58,0.61l0.45,0.07L15.72,14  l-1.41,0.71l-0.97-1.68l-0.33,0.76l0.73,1.6l0.89,0.26l-1.44,0.77l-1.88,2.69l-1.6,0.13l-0.35-1.55l0.26-2.28L7.8,14.93l-0.6-1.45  l0.29-1.39l1.74-0.71l1.64,0.94l0.87-0.28l1.29,0.35l0.29-1.65l-1.66-0.55l-0.02,1.07l-1.06-0.05l-0.09-1.14l-1.31,0.29l0.1-1  L8.73,8.72l0.85-0.77l1.38,0.77l0.43-0.91l1.09-0.46l0.73,0.32l0.74-0.15l0.32-0.77C13.15,5.98,11.75,5.52,10.05,5.52z"/>
  </svg>
</template>
<script>
export default {
  name: 'IconServiceInternational'
}
</script>
<style scoped>
  .st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>
