<template lang="pug">
  .Service
    .Service-name {{ service.name }}
      .Service-flightBox
        .Service-flight-type(:title="getFlightType")
          component(:is="getFlightTypeIconComponent")

        .Service-type-icon(:title="getServiceType")
          component(:is="getServiceTypeIconComponent")

    .Service-title
      .Service-airport {{ terminalName }}

    .Service-description {{ service.description }}
    .Service-options
      .Service-icons(v-for="option in options" :class="{ 'optionsName': showOptionsName }" :title="option.name")
        component(:is="option.componentName")
        span(v-if="showOptionsName") {{ option.name }}

      .Service-more(@click="showDetails")
        span {{ 'Подробности...' }}

</template>

<script>
import { mapState } from 'vuex'
import mapOptionsIcons from "@/utils/mapOptionsIcons";
import IconServiceArrival from "@/components/icons/IconServiceArrival";
import IconServiceDeparture from "@/components/icons/IconServiceDeparture";
import IconServiceDomestic from "@/components/icons/IconServiceDomestic";
import IconServiceInternational from "@/components/icons/IconServiceInternational";
import sendPostMessage from "@/utils/sendPostMessage";
export default {
  name: "Service",
  components: { IconServiceArrival, IconServiceDeparture, IconServiceDomestic, IconServiceInternational },
  props: ['showOptionsName'],
  computed: {
    ...mapState (['widgetId', 'terminal', 'airport', 'service']),
    terminalName () {
      const airportName = this.airport.name
      const terminalName = this.terminal.name
      if (airportName === terminalName) return airportName
      return `${airportName} ${terminalName}`
    },
    getFlightTypeIconComponent () {
      switch (this.service.type_flight) {
        case 'domestic':
          return IconServiceDomestic
        case 'international':
          return IconServiceInternational
        default:
          return ''
      }
    },
    getFlightType () {
      switch (this.service.type_flight) {
        case 'domestic':
          return 'Внутренний'
        case 'international':
          return 'Международный'
        default:
          return ''
      }
    },
    getServiceTypeIconComponent () {
      switch (this.service.type) {
        case 'arrival':
          return IconServiceArrival
        case 'departure':
          return IconServiceDeparture
        default:
          return ''
      }
    },
    getServiceType () {
      switch (this.service.type_flight) {
        case 'domestic':
          return 'Вылет'
        case 'international':
          return 'Прилет'
        default:
          return ''
      }
    },
    options () {
      return this.service.option_categories.map(o => {
        o.componentName = mapOptionsIcons[o.type]
        return o
      })
        .slice(0, 6)
    }
  },
  methods: {
    showDetails () {
      sendPostMessage('details-show', { id: this.widgetId })
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import '../assets/variables.styl'
  .Service
    position: relative
    &-name
      padding: 0
      font-weight: bold
      font-size: var(--theme_header_size)
      line-height: 1
      margin-bottom: 10px
      display: flex
      justify-content space-between
      width: 100%
      flex: auto
      @media (min-width: $mobile-center-min-width)
        justify-content: flex-start

    &-title
      display: flex
      flex: 1
      padding-bottom: ($article-gap - 15px)
      @media (min-width: $mobile-center-min-width)
        padding-bottom: ($article-gap - 15px)
      div:not(:first-child)
        margin-left: $article-gap-sm
    &-flightBox
      display: flex
      flex: none
      svg
        flex: none
        height: var(--theme_header_size)
        fill: var(--theme_text_color)
        margin-left: ($article-gap-sm - 2px)
        position: relative
        top: calc(var(--theme_header_size) / (-20))

    &-description
      padding-bottom: ($article-gap - 10px)

    &-options
      display: flex
      flex-wrap: wrap
      padding-bottom: ($article-gap - 15px)

    &-more
      cursor: pointer
      color: var(--theme_link_color)
      span
        border-bottom: 1px dashed var(--theme_link_color)
        line-height: 1
      &:hover
        opacity: 0.7

    &-icons
      display: flex
      padding-right: $article-gap-sm
      box-sizing: border-box
      padding-bottom: $article-gap-sm
      svg
        flex: none
        height: calc(var(--theme_font_size) + 4px)
        fill: var(--theme_text_color)
      &.optionsName
        flex: none
        width: 100%
        @media(min-width: $mobile-min-width-center)
          width: 50%
        @media(min-width: $maxCore-width)
          width: 33.33%
        span
          font-size: calc(var(--theme_font_size) - 2px)
          padding-top: 2px
          padding-left: $article-gap-sm
          width: 100%
          box-sizing: border-box
          overflow-wrap: break-word
          word-break: break-word
          hyphens: auto
</style>
