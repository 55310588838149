<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path d="M10.76,10.64c-0.95,0.16-1.71,0.17-2.71,0.04c-0.3,1.48,0.14,2.32,1.25,2.32C10.7,13.01,11.03,12.28,10.76,10.64z"/>
    <path d="M19.23,16.2c-0.32-0.29-0.83-0.26-1.12,0.06c-0.13,0.15-0.56,0.77-1.03,1.1c-0.43-1.54-0.71-2.12-1.42-4.17s-0.41-3.76,0.22-4.32c0,0,0.9,0.78,1.79,0.89c0,0,0.1,1.05,1.17,0.47c0.29-0.15,0.39-0.8,0.1-1.22l-0.23-0.33c0,0-0.6-1.85-1.13-2.39c0,0-0.19-0.93,0-1.36c0,0-0.33,0.19-0.43,0.37c0,0,0.04-0.56,0.15-0.66c0,0-1.01,0.62-1.07,0.97c0,0-2.04-0.6-3.71,1.01c0,0,0.64-0.16,0.87-0.08c0,0-2.04,1.15-2.41,2.51c0,0,0.66-0.43,0.87-0.37c0,0-0.36,0.59-0.29,1.5l0,0c0.41,2.39-0.58,3.47-1.5,3.52c-0.21,0.01-0.27,0.21-0.27,0.38l0,0.96c0,0.21,0.16,0.38,0.37,0.4c2.08,0.11,4.15,1.1,5.41,2.7c-1.6,0.78-3.54,1.23-5.56,1.23c-2.03,0-3.99-0.45-5.59-1.25c1.1-1.39,2.46-2.33,4.24-2.62c0.19-0.03,0.33-0.2,0.33-0.39l0-1.03c0-0.17-0.11-0.32-0.27-0.38c-0.79-0.26-1.58-0.86-1.51-2.66l0-0.53c-0.81-0.14-1.12-0.32-1.12-0.32C5.99,9.52,5.59,8.58,5.1,8.15C4.51,7.64,4.09,7.45,3.31,7.45c-0.1,0-0.18,0-0.26,0.01c-0.01,0-0.01,0-0.02,0C2.88,7.46,2.77,7.48,2.67,7.5C2.3,7.58,1.88,7.76,1.5,8.14c0,0,0.39-0.1,0.53-0.05c0,0-0.59,0.32-1.01,0.81c0.3-0.1,0.27-0.12,0.53-0.14C1.17,8.97,0.97,9.34,0.7,9.92C1.02,9.71,1.14,9.54,1.4,9.49c-0.28,0.22-0.66,0.8-0.63,1.31c0.31-0.37,0.84-0.83,1.57-0.89c0.8-0.07,1.59,0.3,2.02,0.63c0.43,0.33,0.53,0.81,0.34,1.43c-0.2,0.62-1.31,2.8-1.72,5.35c-0.55-0.39-1.01-0.95-1.11-1.07c-0.29-0.33-0.79-0.36-1.12-0.07c-0.33,0.29-0.36,0.79-0.07,1.12c2.03,2.29,5.51,3.66,9.32,3.66c3.8,0,7.27-1.36,9.3-3.64C19.59,16.99,19.56,16.49,19.23,16.2z M16.98,7.01c0.18,0,0.32,0.15,0.32,0.32c0,0.18-0.14,0.32-0.32,0.32c-0.18,0-0.32-0.14-0.32-0.32C16.66,7.15,16.81,7.01,16.98,7.01z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesKids'
}
</script>
