<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M10.11,4.38c-11.21,0-11.75,17.06,0.19,17.06C21.2,21.43,21.27,4.38,10.11,4.38z M10.26,20.17c-10.18,0-9.72-14.54-0.16-14.54C19.61,5.63,19.55,20.17,10.26,20.17z"/>
    <polygon class="st0" points="9.92,13.05 14.5,9.27 14.82,9.58 10.17,14.56 9.71,14.55 6.98,11.91 7.47,11.33 "/>
    <polygon class="st0" points="4.03,14.11 4.03,13.19 5.55,13.38 5.55,13.92 "/>
    <polygon class="st0" points="15.99,13.19 15.99,14.11 14.47,13.92 14.47,13.38 "/>
    <polygon class="st0" points="10.54,18.99 9.62,18.99 9.81,17.47 10.34,17.47 "/>
    <polygon class="st0" points="9.62,6.99 10.54,6.99 10.34,8.51 9.81,8.51 "/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesClock'
}
</script>

<style scoped>
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
</style>
