<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path d="M18.05,15.79l-3.57-1.29L13.1,9.64C13.03,9.32,11.16,8.4,10,8.4H7.99c-1.09,0-2.85,0.82-2.93,1.25C4.91,10.45,3.1,19.78,3.1,19.78c-0.06,0.58,0.29,0.8,0.87,0.87c0.58,0.06,0.96-0.03,1.03-0.61l1.13-5.69l-0.19,5.02h0.13V22h2.7v-2.64l0,0h0.61l0,0L9.82,22h2.67l-0.42-2.64l0,0h0.45c0-0.23-0.45-5.75-0.45-5.75c1.09,2.93,1.51,2.83,2.48,3.18c0,0,0,0,0.03,0l2.73,1c0.55,0.19,0.93-0.13,1.13-0.68C18.66,16.53,18.59,15.99,18.05,15.79z M8.79,8.4h0.64c0.16,0,0.26,0.22,0.26,0.35L9.62,9.36C9.59,9.53,9.5,9.62,9.37,9.62c-0.06,0-0.1-0.03-0.23-0.03c-0.13,0-0.16,0.03-0.26,0.03c-0.16,0-0.26-0.1-0.26-0.26L8.56,8.75C8.53,8.63,8.63,8.4,8.79,8.4z M10.04,15.92l-0.71,0.74c-0.06,0.06-0.13,0.06-0.19,0l-0.71-0.74c-0.1-0.16,0.35-5.92,0.35-5.92c0.1-0.16,0.55-0.16,0.64,0C9.43,10.01,10.08,15.89,10.04,15.92z"/>
    <path class="st0" d="M9.04,1.46c-3.64,0-4.5,1.95-2.18,2.71c-0.01,0.16,0,0.33,0.01,0.51C6.98,6.38,7.76,7.24,9,7.24c1.24,0,2.04-0.87,2.14-2.63c0.01-0.15,0.01-0.29,0.01-0.43C13.38,3.44,12.7,1.46,9.04,1.46z M7.97,2.87c0.4-0.02,1.47-0.02,2.05,0c0,0.75-0.38,1.03-1.02,1.03C8.48,3.91,7.97,3.67,7.97,2.87z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesAssistance'
}
</script>

<style scoped>
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
</style>
