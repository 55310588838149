<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path d="M6.62,4.57c0.27-0.31,0.6-0.47,1.01-0.49C7.8,4.07,7.95,4.09,8.1,4.1c0.2-0.29,0.41-0.55,0.65-0.77C8.8,3.29,8.86,3.25,8.91,3.21C8.45,3.09,8,3.06,7.58,3.08C6.9,3.12,6.31,3.4,5.87,3.91C5.11,4.78,4.78,6.3,4.94,8.19C4.96,8.47,5.2,8.67,5.48,8.65c0.28-0.02,0.48-0.26,0.46-0.54C5.8,6.51,6.05,5.22,6.62,4.57z"/>
    <path d="M7.5,10.73c0.38,0,0.68,0.33,0.68,0.73c0,0.4-0.31,0.73-0.68,0.73c-0.38,0-0.68-0.33-0.68-0.73C6.82,11.06,7.12,10.73,7.5,10.73 M7.5,10.42c-0.55,0-0.99,0.47-0.99,1.04S6.95,12.5,7.5,12.5c0.55,0,0.99-0.47,0.99-1.04S8.04,10.42,7.5,10.42L7.5,10.42z"/>
    <path d="M13.52,10.71c0.37,0,0.68,0.33,0.68,0.73c0,0.4-0.3,0.73-0.68,0.73c-0.37,0-0.68-0.33-0.68-0.73C12.85,11.03,13.15,10.71,13.52,10.71 M13.52,10.4c-0.55,0-0.99,0.46-0.99,1.04c0,0.57,0.44,1.04,0.99,1.04c0.55,0,0.99-0.46,0.99-1.04C14.51,10.86,14.07,10.4,13.52,10.4L13.52,10.4z"/>
    <path d="M13.52,11.93c-0.04,0-0.08,0-0.12-0.01c-0.27-0.06-0.43-0.33-0.37-0.6c0.74-3.09,0.68-5.6-0.15-6.71c-0.32-0.44-0.77-0.66-1.36-0.7c-0.7-0.04-1.27,0.15-1.73,0.58C8.72,5.48,8.13,7.76,8,11.45c-0.01,0.28-0.24,0.53-0.52,0.48C7.21,11.92,6.99,11.69,7,11.42c0.14-4.03,0.82-6.47,2.12-7.67c0.67-0.61,1.49-0.89,2.46-0.84c0.89,0.05,1.6,0.42,2.11,1.1c1.39,1.87,0.8,5.53,0.32,7.54C13.96,11.78,13.75,11.93,13.52,11.93z"/>
    <path d="M17,7.95H3.99H3.66H2.97L1.42,20.01l1.54,0.35L3.99,21h13.99L17,7.95z M4.41,20L4.8,8.95h11.26L16.9,20H4.41z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesShop'
}
</script>
