<template lang="pug">
  .GalleryBlock(
    @click="showGallery"
    :style="`background-image: url(${ previewUrl })`"
    :class="{ 'has-gallery': service.gallery.length }"
  )
    img(:src="previewUrl")
    .GalleryBlock-photos(v-if="service.gallery.length")
      .GalleryBlock-icon
        IconPhoto

      .GalleryBlock-text Галерея
</template>

<script>
import IconPhoto from "@/components/icons/IconPhoto";

import { mapState } from "vuex";
import sendPostMessage from "@/utils/sendPostMessage";
export default {
  name: 'GalleryBlock',
  components: { IconPhoto },
  props: ['service'],
  computed: {
    ...mapState(['widgetId', 'apiUrl']),
    previewUrl () {
      if (!this.service.gallery.length) return 'https://cdn.vip-zal.ru/images/default_bg.jpeg'
      return this.getUrl(this.service.gallery[0]?.id) || 'https://cdn.vip-zal.ru/images/default_bg.jpeg'
    },
  },
  methods: {
    checkApiUrl () {
      let response = this.apiUrl
      if (response) {
        if (response.slice(-1) !== '/') {
          response = response + '/'
        }
      }
      return response
    },
    getUrl (id) {
      return this.checkApiUrl() + `gallery/data?id=${id}&width=${300}`
    },
    showGallery () {
      if (!this.service.gallery.length) return
      const data = {
        from: 'showGallery',
        data: this.service.gallery,
      }
      sendPostMessage('gallery-show', { data, id: this.widgetId })
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import '../assets/variables.styl'
  .GalleryBlock
    position: relative
    overflow: hidden
    width: 100%
    flex: none
    height: 150px
    background-repeat: no-repeat
    background-attachment: scroll
    background-position: 50% 50%
    background-size: cover
    margin-bottom: ($article-gap - 11px)
    &.has-gallery
      cursor: pointer

    @media (min-width: $mobile-center-min-width)
      max-width: 30%
      height auto
      margin-bottom: 0

    img
      width: auto
      height: 100%
      min-width: 100%
      min-height: 100%
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      opacity: 0

    &-photos
      position: absolute
      bottom: 0
      display: flex
      align-items: center
      background: #0000007d
      left: 0
      padding: 6px 0
      .is-gallery-right &
        right: 0
        left: auto

    &-icon
      svg
        height: calc(var(--theme_font_size) - 2px)
        fill: #fff
        margin-left: 10px

    &-text
      margin: 0 10px
      line-height: 1
      color: #fff
</style>
