<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path class="st0" d="M18.05,13.01C14.16,8.51,6.21,8.31,1.94,13l-1.92-1.91C5.07,5.51,15.01,5.54,20,11.08L18.05,13.01zM16.29,14.76l-1.89,1.87c-1.91-2.68-6.85-2.65-8.84-0.03l-1.85-1.85C6.78,11.04,13.26,11.07,16.29,14.76z M12.41,18.6L9.99,21l-2.43-2.42C8.64,16.88,11.3,16.69,12.41,18.6z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesWifi'
}
</script>
