<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 6 17" style="enable-background:new 0 0 6 17;" xml:space="preserve">
    <g>
      <polygon class="st0" points="6,6.3 0,6.3 0,11.7 1.2,11.7 1.2,17 2.4,17 2.4,11.7 3.6,11.7 3.6,17 4.8,17 4.8,11.7 6,11.7  "/>
      <circle class="st0" cx="3" cy="3.3" r="1.8"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconMan'
}
</script>

<style scoped>

</style>
