<template lang="pug">
  div
    Loader.GalleryBlock(v-if="!service.id")
    .core(
      v-if="service.id"
      :class="{ 'is-ui-column': uiDirection === 'column', 'is-gallery-right': galleryPosition === 'right' }"
    )
      GalleryBlock(
        v-if="service.id && terminal.id"
        :service="service"
        :terminal="terminal"
      )
      .ServiceInfoBlock
        Service(
          v-if="terminal.id && service.id"
          :show-options-name="showOptionsName"
        )
        Order(
          :class="{ 'light-button': buttonThemeType === 'light' }"
          :id="service.id"
        )
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import GalleryBlock from "@/components/GalleryBlock";
import Service from "@/components/Service";
import Order from "@/components/Order";
import sendPostMessage from "@/utils/sendPostMessage";
import Loader from "@/components/Loader";

const queryString = require('query-string')

export default {
  name: 'Core',
  components: {
    Loader,
    Order,
    Service,
    GalleryBlock
  },
  data () {
    return {
      apiUrlInit: false,
      themeInit: false,
      galleryPosition: '',
      showOptionsName: '',
      uiDirection: '',
      buttonThemeType: ''
    }
  },
  created () {
    const {
      widget_id,
      apiUrl,
      service_id,
      gallery_position = 'right', // 'left', 'right'
      show_options_name = false, // true, false
      ui_direction = 'row', // row, column
      button_theme_bg_color = 'FFBF25',
      button_theme_border_width = '0',
      button_theme_border_color = 'fff',
      button_theme_border_radius = '0',
      button_theme_text_color = 'fff',
      button_theme_bg_hover = '888',
      button_theme_text_hover = 'fff',
      button_theme_type = 'fill', // 'fill', 'light'
      theme_background = 'fff',
      theme_border_color = '000',
      theme_border_radius = '5px',
      theme_border_width = '0',
      theme_text_color = '000',
      theme_link_color = '007aff',
      theme_font_size = '14px',
      theme_header_size = '16px',
      theme_main = 'FFBF25',
      theme_padding = '0',
      theme_font_family

    } = queryString.parse(location.search)
    this.buttonThemeType = button_theme_type
    this.setWidgetId(widget_id)
    this.initApiUrl(apiUrl)
    this.galleryPosition = gallery_position
    this.showOptionsName = show_options_name
    this.uiDirection = ui_direction
    if (!this.themeInit) {
      const theme = {
        button_theme_bg_color: '#' + button_theme_bg_color,
        button_theme_text_color: '#' + button_theme_text_color,
        button_theme_border_color: '#' + button_theme_border_color,
        button_theme_bg_hover: '#' + button_theme_bg_hover,
        button_theme_text_hover: '#' + button_theme_text_hover,
        theme_background: '#' + theme_background,
        theme_border_color: '#' + theme_border_color,
        theme_text_color: '#' + theme_text_color,
        theme_link_color: '#' + theme_link_color,
        theme_main: '#' + theme_main,
        button_theme_border_width,
        button_theme_border_radius,
        theme_border_radius,
        theme_border_width,
        theme_font_size,
        theme_header_size,
        theme_padding,
        theme_font_family,
      }
      this.initTheme(theme)
    }
    if (service_id) this.setDataByServiceId(Number(service_id))
  },
  computed: {
    ...mapState(['terminal', 'service', 'apiUrl', 'theme', 'widgetId']),
  },
  methods: {
    ...mapMutations(['setApiUrl', 'setTheme', 'setWidgetId']),
    ...mapActions(['setDataByServiceId']),
    initTheme (theme) {
      this.setTheme(theme)
      this.themeInit = true
    },
    initApiUrl (url) {
      if (url && url !== 'undefined') {
        this.setApiUrl(url)
      }
    },
  },
  mounted () {
    setInterval(() => {
      const height = document.body.scrollHeight
      sendPostMessage('update-height', { id: this.widgetId, height })
    }, 500)
    window.addEventListener('message', (e) => {
      // If stringify object
      if (e.data && typeof e.data === 'string' && e.data.match(/{*/)[0]) {
        const data = JSON.parse(e.data)
        if (data.type === 'details-get-data') {
          const { terminal, service, apiUrl, theme, widgetId: id } = this
          sendPostMessage('details-set-data', {
            data: { terminal, service, apiUrl, theme },
            id,
            theme
          })
        }
        if (data.type === 'gallery-get-data') {
          const { service: data, widgetId: id } = this
          data.apiUrl = this.apiUrl
          sendPostMessage('gallery-set-data', { data, id })
        }
      }
    })
  }
}
</script>

<style lang="stylus" scoped>
  @import '/assets/variables.styl'
  .core
    box-sizing border-box
    width: 100%
    max-width: 100%
    font: var(--theme_font_size)/1.4 var(--theme_font_family)
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-align: left
    color: var(--theme_text_color)
    border: var(--theme_border_width) solid var(--theme_border_color)
    border-radius: var(--theme_border_radius)
    background: var(--theme_background)
    overflow: hidden
    @media (min-width: $mobile-center-min-width)
      display: flex
      flex-direction: row
      align-items: stretch
    // тут все стили для расположения элементов по-вертикали (по-умолчанию по-горизонтали)

    &.is-ui-column
      visibility: visible

    // тут все стили, когда галерея справа (по-умолчанию слева)

    &.is-gallery-right
      flex-direction: row-reverse

  .ServiceInfoBlock
    flex: 1
    padding: 0 var(--theme_padding) var(--theme_padding)
    @media (min-width: $mobile-center-min-width)
      padding: var(--theme_padding) var(--theme_padding) var(--theme_padding) $serviceInfoBlockPadding
      .is-gallery-right &
        padding: var(--theme_padding) $serviceInfoBlockPadding var(--theme_padding) var(--theme_padding)
</style>
