<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path id="Path_118_21_" d="M18.87,13.34c-0.25-0.55-0.79-0.89-1.39-0.89c-0.81,0-1.47,0.66-1.47,1.47v2.45c0,0.54-0.03,0.98-0.03,0.98c-3.53-0.78-8.43-0.78-11.96,0l-0.03-3.43c0-0.81-0.66-1.47-1.47-1.47c-0.61,0-1.16,0.36-1.4,0.93c-0.25,0.63-0.1,1.94,0.61,1.94c0.32,0,0.3,0.42,0.3,0.64v3.71c0,0.54,0.44,0.67,0.98,0.67h13.99c0.54,0,0.98-0.13,0.98-0.67v-3.6c0-0.3-0.08-0.74,0.29-0.74C18.99,15.31,19.15,13.99,18.87,13.34z"/>
    <path id="Path_121_17_" d="M4.92,13.86V16c3.16-0.55,7.01-0.55,10.17,0v-2.14c0-1.18,0.47-2.16,1.58-2.4C16.45,7.95,15.75,5.5,9.61,5.5c-5.46,0-6.07,2.75-6.27,5.96C4.44,11.7,4.92,12.68,4.92,13.86z M11.34,8.87h0.39V8.48c0-0.17,0.06-0.31,0.23-0.31c0.17,0,0.23,0.14,0.23,0.31v0.39h0.39c0.17,0,0.31,0.06,0.31,0.23c0,0.17-0.14,0.23-0.31,0.23h-0.39v0.39c0,0.17-0.06,0.31-0.23,0.31c-0.17,0-0.23-0.14-0.23-0.31V9.33h-0.39c-0.17,0-0.31-0.06-0.31-0.23C11.03,8.93,11.17,8.87,11.34,8.87z M11.34,12.76h0.39v-0.39c0-0.17,0.06-0.31,0.23-0.31c0.17,0,0.23,0.14,0.23,0.31v0.39h0.39c0.17,0,0.31,0.06,0.31,0.23c0,0.17-0.14,0.23-0.31,0.23h-0.39v0.39c0,0.17-0.06,0.31-0.23,0.31c-0.17,0-0.23-0.14-0.23-0.31v-0.39h-0.39c-0.17,0-0.31-0.06-0.31-0.23C11.03,12.82,11.17,12.76,11.34,12.76z M7.42,8.87h0.39V8.48c0-0.17,0.06-0.31,0.23-0.31c0.17,0,0.23,0.14,0.23,0.31v0.39h0.39c0.17,0,0.31,0.06,0.31,0.23c0,0.17-0.14,0.23-0.31,0.23H8.27v0.39c0,0.17-0.06,0.31-0.23,0.31c-0.17,0-0.23-0.14-0.23-0.31V9.33H7.42c-0.17,0-0.31-0.06-0.31-0.23C7.11,8.93,7.24,8.87,7.42,8.87L7.42,8.87z M7.42,12.76h0.39v-0.39c0-0.17,0.06-0.31,0.23-0.31c0.17,0,0.23,0.14,0.23,0.31v0.39h0.39c0.17,0,0.31,0.06,0.31,0.23c0,0.17-0.14,0.23-0.31,0.23H8.27v0.39c0,0.17-0.06,0.31-0.23,0.31c-0.17,0-0.23-0.14-0.23-0.31v-0.39H7.42c-0.17,0-0.31-0.06-0.31-0.23C7.11,12.82,7.24,12.76,7.42,12.76L7.42,12.76z"/>
    <polygon class="st0" points="3.77,20.99 3.34,22 2,22 2.01,20.81 "/>
    <polygon class="st0" points="17.97,20.81 17.99,22 16.66,22 16.22,20.99 "/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesLounge'
}
</script>

<style scoped>
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
</style>
