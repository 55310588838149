export default {
  thumbs: {
    spaceBetween: 10,
    slidesPerView: 'auto',
    lazy: {
      loadPrevNext: true
    },
    freeMode: true,
    // loopedSlides: 11, // looped slides should be the same
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    autoScrollOffset: 500
  },
  main: (thumbs) => {
    return {
      lazy: {
        loadPrevNext: true
      },
      keyboard: true,
      slideToClickedSlide: true,
      // loopedSlides: 11, // looped slides should be the same
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      thumbs: { swiper: thumbs }
    }
  }
}
