import themeSetter from '@/utils/themeSetter'
import Vue from 'vue'
import Vuex from 'vuex'
import sendPostMessage from "@/utils/sendPostMessage";

Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  state: {
    apiUrl: '',
    terminal: {},
    airport: {},
    service: {},
    widgetId: null,
    theme: {}
  },
  mutations: {
    setApiUrl (state, data) {
      state.apiUrl = data
    },
    setTheme (state, theme) {
      state.theme = themeSetter(theme)
    },
    setTerminal (state, terminal) {
      state.terminal = terminal
    },
    setAirport (state, airport) {
      state.airport = airport
    },
    setService (state, service) {
      state.service = service
    },
    setWidgetId (state, id) {
      state.widgetId = id
    }
  },
  actions: {
    setDataByServiceId ({ state, commit }, serviceId) {
      Vue.prototype.$api.fetchService(serviceId, state.apiUrl).then(res => {
        const terminalId = Number(res.data.terminal_id)
        const service = res.data
        Vue.prototype.$api.fetchServices({ terminal_id: terminalId }, state.apiUrl).then(res => {
          const serviceMore = res.data.find(service => service.id === serviceId)
          commit('setService', Object.assign({}, service, serviceMore))
          Vue.prototype.$api.fetchAirportByServiceId(serviceId, state.apiUrl).then(res => {
            commit('setTerminal', res.data.terminals.find(terminal => terminal.id === terminalId))
            state.airport = res.data
            Vue.prototype.$api.fetchPrice(serviceId, state.apiUrl).then(res => {
              commit('setService', Object.assign({}, service, serviceMore, res.data))
            })
            sendPostMessage('terminal-fetched', { id: state.widgetId })
          })
        })
      })
    }
  },
})

export default store
