<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <path d="M10.62,8.73H6.85v9.24H8.1v-3.68h2.4c2.39,0,3.25-1.17,3.25-2.9C13.75,9.47,12.5,8.73,10.62,8.73z M10.37,13.24H8.1V9.77h2.27c1.43,0,2.11,0.49,2.11,1.64C12.48,12.62,11.84,13.24,10.37,13.24z"/>
    <path d="M17.21,5.4L2.64,5.51c-0.26,0-0.46,0.21-0.47,0.46L1.94,20.53c0,0.25,0.2,0.45,0.44,0.45l15.19,0.07c0.27,0,0.48-0.22,0.47-0.48L17.68,5.85C17.67,5.6,17.46,5.39,17.21,5.4z M16.42,7.12l0.37,12.18c0.01,0.27-0.21,0.49-0.47,0.48L3.68,19.71c-0.26,0-0.47-0.22-0.47-0.48l0.23-12c0-0.25,0.21-0.46,0.47-0.46l12.03-0.11C16.2,6.66,16.41,6.86,16.42,7.12z"/>
    <path d="M6.7,17.98V8.65h3.02c1.15,0,1.89,0.05,2.24,0.14c0.53,0.14,0.98,0.44,1.34,0.91c0.36,0.47,0.54,1.08,0.54,1.82c0,0.57-0.1,1.05-0.31,1.45c-0.21,0.39-0.47,0.7-0.79,0.92c-0.32,0.22-0.65,0.37-0.98,0.44c-0.45,0.09-1.1,0.13-1.95,0.13H8.59v3.52H6.7z M8.59,10.23v2.65h1.03c0.74,0,1.24-0.05,1.49-0.15c0.25-0.1,0.45-0.25,0.59-0.46c0.14-0.21,0.21-0.45,0.21-0.73c0-0.34-0.1-0.62-0.3-0.84c-0.2-0.22-0.45-0.36-0.76-0.41c-0.23-0.04-0.68-0.06-1.36-0.06H8.59z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSubservicesParking'
}
</script>
