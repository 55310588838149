<template lang="pug">
  .ServiceDetails
    .Services-infoBlock-box.Services-infoBlock-box-dark(v-if="mainOptions.length")
      .Services-infoBlock-box-item.is-service-more(v-for="option in mainOptions")
        component.Services-infoBlock-box-item-icons(:is="map[option.type]")
        div(v-if="typeof getOptionText(option) === 'string'") {{ getOptionText(option) }}
        div(v-else)
          template(v-if="getOptionText(option)")
            div {{ getOptionText(option).includes }}
            div {{ getOptionText(option).additional }}

      .Services-infoBlock-box-item.is-service-more(v-for="option in additionalOptions")
        component.Services-infoBlock-box-item-icons--colored(:is="map[option.type]")
        div(v-if="typeof getOptionText(option) === 'string'") {{ getOptionText(option) }}
</template>

<script>
import mapOptionsIcons from '@/utils/mapOptionsIcons'

import { mapState } from 'vuex'
import IconSubservicesAlcohol from '@/components/icons/options/IconSubservicesAlcohol'
import IconSubservicesLounge from '@/components/icons/options/IconSubservicesLounge'
import IconSubservicesClock from '@/components/icons/options/IconSubservicesClock'
import IconSubservicesMap from '@/components/icons/options/IconSubservicesMap'
import IconSubservicesPassport from '@/components/icons/options/IconSubservicesPassport'
import IconSubservicesTransfer from '@/components/icons/options/IconSubservicesTransfer'
import IconSubservicesAssistance from '@/components/icons/options/IconSubservicesAssistance'
import IconSubservicesLuggage from '@/components/icons/options/IconSubservicesLuggage'
import IconSubservicesFood from '@/components/icons/options/IconSubservicesFood'
import IconSubservicesParking from '@/components/icons/options/IconSubservicesParking'
import IconSubservicesAssist from '@/components/icons/options/IconSubservicesAssist'
import IconSubservicesConversation from '@/components/icons/options/IconSubservicesConversation'
import IconSubservicesKids from '@/components/icons/options/IconSubservicesKids'
import IconSubservicesMisc from '@/components/icons/options/IconSubservicesMisc'
import IconSubservicesPress from '@/components/icons/options/IconSubservicesPress'
import IconSubservicesPrinter from '@/components/icons/options/IconSubservicesPrinter'
import IconSubservicesShop from '@/components/icons/options/IconSubservicesShop'
import IconSubservicesWash from '@/components/icons/options/IconSubservicesWash'
import IconSubservicesWifi from '@/components/icons/options/IconSubservicesWifi'
import IconSmokingAreas from '@/components/icons/options/IconSmokingAreas'
import IconNoSmokingAreas from '@/components/icons/options/IconNoSmokingAreas'
import IconAnimalsAllowed from '@/components/icons/options/IconAnimalsAllowed'
import IconDisabledAccess from '@/components/icons/options/IconDisabledAccess'
import IconTaxRefund from '@/components/icons/options/IconTaxRefund'

export default {
  name: 'ServiceDetails',
  props: ['price', 'service', 'type', 'checkedServiceId', 'noPriceBlock', 'clearBackground', 'location', 'transparentBackground', 'stepInfo'],
  components: {
    // ServicePriceBlock,
    IconSubservicesParking,
    IconSubservicesFood,
    IconSubservicesLuggage,
    IconSubservicesAssistance,
    IconSubservicesTransfer,
    IconSubservicesPassport,
    IconSubservicesClock,
    IconSubservicesMap,
    IconSubservicesLounge,
    IconSubservicesAlcohol,
    IconSubservicesAssist,
    IconSubservicesConversation,
    IconSubservicesKids,
    IconSubservicesMisc,
    IconSubservicesPress,
    IconSubservicesPrinter,
    IconSubservicesShop,
    IconSubservicesWash,
    IconSubservicesWifi,
    IconSmokingAreas,
    IconNoSmokingAreas,
    IconTaxRefund,
    IconDisabledAccess,
    IconAnimalsAllowed
  },
  data () {
    return {
      map: mapOptionsIcons
    }
  },
  computed: {
    ...mapState({
      terminal: (state) => state.terminal,
      theme: (state) => state.theme
    }),
    isGalleryEnable () {
      const isDme = this.terminal.id === 125
      const isVipOrBiz = ['vip-zal', 'business-zal'].includes(this.service.category.id)
      return isDme && isVipOrBiz
    },
    importantOptions () {
      const importantOptionsCats = this.mainOptions.filter(o => o.is_important)
      return importantOptionsCats.filter(category => {
        return category.filter(o => o.is_important).length
      })
    },
    mainOptions () {
      const priorityFilter = (arr, value) =>
        [...arr.filter(n => n.priority <= value.priority), value, ...arr.filter(n => n.priority > value.priority)]
      const filtered = this.service.option_categories.reduce(priorityFilter, [])
      const availabilityOptions = filtered.filter(o => o.options.filter(opt => opt.availability !== 2).length)
      return availabilityOptions.filter(o => o.options.length)
    },
    additionalOptions () {
      const options = []
      if (this.service.has_smoking_areas) {
        options.push({
          type: 'has_smoking_areas',
          description: 'Есть места для курения'
        })
      }
      if (!this.service.has_smoking_areas && this.service.has_smoking_areas !== null) {
        options.push({
          type: 'no_smoking_areas',
          description: 'Нет мест для курения'
        })
      }
      if (this.service.has_tax_refund) {
        options.push({
          type: 'has_tax_refund',
          description: 'Оформление Tax Refund'
        })
      }
      if (this.service.has_disabled_access) {
        options.push({
          type: 'has_disabled_access',
          description: 'Доступно для лиц с ограниченными возможностями'
        })
      }
      if (this.service.has_animals_allowed) {
        options.push({
          type: 'has_animals_allowed',
          description: 'Можно с животными'
        })
      }
      return options
    },
    dotAfterOpeningHours () {
      if (this.service.hours_to_stay) {
        return this.service.hours_to_stay + this.service.hours_to_stay === '.' ? '' : '.'
      } else {
        return ''
      }
    }
  },
  methods: {
    getOptionText (optionCategory) {
      if (optionCategory.description) return optionCategory.description
      return this.getDescriptionString(optionCategory.options || [])
    },
    getDescriptionString (options) {
      let includes
      let additional
      if (this.stepInfo === '3') {
        includes = options
          .filter(option => option.availability === 0)
          .map(option => option.short_description || option.name)
        additional = options
          .filter(option => option.availability === 1)
          .map(option => option.short_description || option.name)
      } else {
        includes = options
          .filter(option => option.availability === 0)
          .filter(option => option.is_important === true)
          .map(option => option.short_description || option.name)
        additional = options
          .filter(option => option.availability === 1)
          .filter(option => option.is_important === true)
          .map(option => option.short_description || option.name)
      }
      if (!additional.length) {
        const string = includes.join(', ')
        return this.capitalizeString(string)
      }
      if (!includes.length) {
        const string = additional.join(', ')
        return this.capitalizeString(string) + ' – оплата на месте'
      }
      const includesString = includes.join(', ') + ' – включено'
      const additionalString = additional.join(', ') + ' – оплата на месте'
      return {
        includes: includesString,
        additional: additionalString
      }
    },
    capitalizeString (str) {
      return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    }
  }
}
</script>

<style lang="stylus">
@import '../assets/variables.styl'

.Services
  &-infoBlock
    &-moreInfo
      padding: .75rem 0 1rem
      background-color: #fff
      margin: 0 0 -10px 0
      border-radius: 0 0 5px 5px

    &-moreInfoButton
      padding: 0 0 1.25rem 48px

    &-box
      padding: 0 0 3px
      &-dark
        background-color: $background-ServiceDetailsDark
        padding: 18px 0 10px
        &.is-clear-background
          background-color: #fff

      &-item
        padding: 0 15px 7px 48px
        color: #000 !important
        //color: $app-color
        position: relative
        //&.is-service-more
        //  color: var(--text-color) !important
        //  fill: var(--text-color) !important

        &.itemMin
          padding: 5px 15px 7px 15px

        &-icons
          width: 20px
          height: 23px
          display: block
          position: absolute
          left: 15px
          top: -4px
          color: #000
          fill: #000
    //&.is-colored
    //  color: var(--text-color)
    //  fill: var(--text-color)

    &-price
      padding: 14px 15px 7px 15px
      &-value
        font-size: $header-size
        line-height: 1
        padding: 2px 0 14px

      &-text
        padding: 0 15px 7px 0

    &-button
      padding: 0 14px 29px
      .Button-next
        @media(min-width: $max-width)
          margin: 0
</style>
