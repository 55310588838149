<template lang="pug">
  .Loader
    .Rect
      svg
        rect(x="0" y="0" rx="2" ry="2" width="1000" height="600")
          animate(
            attributeName="opacity"
            dur="2s"
            values="0.2;1;0.2"
            repeatCount="indefinite"
            begin="0.1"
          )
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style scoped lang="stylus">
  .Rect
    svg
      width: 100%
      rect
        fill: #f1f1f1
        opacity: 0
</style>
