<template>
    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 95.4 83" style="enable-background:new 0 0 95.4 83;" xml:space="preserve">
    <path class="st0" d="M89.2,71.5L61.2,83L36.6,64.6L0,72.6L6.2,5.8L37,0l28,18.4L95.4,8.1L89.2,71.5z M84,27.5l-1.9,8.9l-5.4,0.8
        l-4.1,8.1l-3.5-5.1l-2.9-0.2L65,39.3L64.8,43l-3.3,1.4l-3.2-4.6l-1.7,1l3.1,5.6l3.9,1.2l-4.1,4.6l-1.2,7.3l-7.9,1.9l-3.5-5l-1-9
        l-6.2-3.2L36.7,38l0.7-3.9l6-3.2l6.4,4.4l7-0.8l-0.1-3.9l-4.5-2.3l-0.6,3.4l-3.5-0.4l-1-4.1l-4,0.7l-2.5-6l2.3-2.5l2.4,2.3l3.1,0.2
        l4.4-5.1L35.4,5.4l-5.7,1.1l0.8,1.2l0.8,5.3l-1.1,0.6l-7.4-5.8l-2.6,0.5l-0.8,6.8l4,3l0.8-5.5l5.6,5L29,21.6l-4.6,3.2L24.2,31
        l-3.5-2.2l-4.9-0.6l0.3,3.1l4.2,1.5l-3.9,2.8l-4.9-2.9L9.4,27L6,66.3l8.9-2.2l4.2-14.9l-2.4-2.7l-1.1-5.3l4.3-6.1L31,41.4l-0.5,2.2
        l5.6,4.3l-3.9,5.5l-1.5,1.9l-3.1,0l-6.7,7.4l16.1-4l24.6,18.2l23.2-9.6l3.9-42L84,27.5z M77.2,63.5l-2.8-1.9l-5.8,4.6l-1.5-6.3
        l5.7-8.8l0.8,2.1l2.3-0.3l0.8-3.7l4.5,4.7L81,60.5L77.2,63.5z"/>
    </svg>
</template>

<script>
export default {
  name: 'IconSubservicesMap'
}
</script>

<style scoped>
    svg {
      fill-rule: evenodd;
      clip-rule: evenodd;
    }
</style>
