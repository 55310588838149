<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 23" style="enable-background:new 0 0 20 23;" xml:space="preserve">
    <g class="st4">
      <path class="st10" d="M18.49,10.89L8.33,13.95l-3.81,1.13L6,19.78l5.9-1.88l8.07-2.58L18.49,10.89z M6.63,18.39l-0.59-2.61l7.02-2.33l1.01,2.77L6.63,18.39z"/>
      <path class="st10" d="M3.68,9.57L3.05,9.01C3.4,8.6,3.58,8.06,3.5,7.56c-0.14-0.88-0.74-0.8-1.05-1.8C2.2,4.97,2.89,3.72,3.63,3.17l0.63,0.59c-0.45,0.37-0.44,0.91-0.3,1.37c0.25,0.82,0.9,0.79,1.05,1.82C5.13,7.82,4.52,9.1,3.68,9.57z"/>
      <path class="st10" d="M1.57,9.43c0.19,0.83,1.27,1.1,1.51,2.13c0.2,0.84-0.64,2.29-1.48,2.77l-0.64-0.56c0.42-0.41,0.72-1.12,0.61-1.61c-0.21-0.88-1.28-1.08-1.52-2.11c-0.22-0.93,0.59-2.2,1.34-2.74L2.02,7.9C1.57,8.27,1.43,8.86,1.57,9.43z"/>
      <polygon class="st10" points="1.6,16.01 3.1,20.66 1.7,21.09 0.02,16.52"/>
      <polygon class="st10" points="3.73,15.33 5.32,19.98 3.8,20.45 2.47,15.74"/>
    </g>
    <path class="st9" d="M11.18,17.27l-6,0l0.02-4.67l3.82,0.08l7.62-7.66l3.21,3.36L11.18,17.27z M11.66,12.22l-1.88,1.76L6.93,14.1L6.7,15.93l3.63,0.16l2.48-2.6L11.66,12.22z M3.81,6.53L3.18,5.97c0.34-0.41,0.53-0.94,0.45-1.43C3.49,3.68,2.9,3.76,2.59,2.78C2.35,2,3.03,0.77,3.76,0.23l0.62,0.58C3.93,1.18,3.94,1.71,4.08,2.17c0.25,0.8,0.88,0.78,1.03,1.79C5.23,4.8,4.63,6.06,3.81,6.53zM1.73,6.38c0.19,0.82,1.25,1.08,1.48,2.1c0.19,0.83-0.63,2.25-1.46,2.72l-0.62-0.55c0.42-0.41,0.71-1.1,0.6-1.58C1.53,8.2,0.48,8,0.24,7C0.03,6.08,0.83,4.84,1.55,4.3l0.62,0.58C1.73,5.24,1.6,5.83,1.73,6.38z M2.08,12.6l0.03,4.63l-1.54-0.02l0.2-4.61C0.76,12.6,2.08,12.6,2.08,12.6z M4.16,12.6l0.1,4.66l-1.43-0.02L3,12.6L4.16,12.6z"/>
    <g>
      <polygon class="st0" points="2.77,13.2 4.08,17.24 2.86,17.61 1.4,13.64"/>
    </g>
    <g>
      <polygon class="st0" points="9.64,12.15 12.73,10.97 13.6,13.38 11.33,13.81 12.2,14.69 18.74,12.6 17.37,8.49 8.85,11.34"/>
      <polygon class="st0" points="9.55,14.4 5.62,15.74 4.84,13.57 8.07,12.67 7.29,11.82 3.66,12.93 4.81,17.02 10.41,15.31"/>
    </g>
    <path class="st9" d="M10.13,2.58c-13.22,0-13.86,20.12,0.23,20.12C23.21,22.7,23.3,2.58,10.13,2.58z M10.31,21.44c-12.32,0-11.76-17.6-0.2-17.6C21.63,3.84,21.56,21.44,10.31,21.44z"/>
    <path class="st9" d="M1.92,2.54C1.72,3.38,0.64,3.65,0.39,4.7c-0.2,0.85,0.65,2.32,1.5,2.8l0.64-0.57C2.1,6.51,1.8,5.8,1.91,5.3c0.21-0.89,1.29-1.1,1.53-2.13C3.67,2.23,2.85,0.95,2.1,0.4L1.46,1C1.91,1.37,2.05,1.97,1.92,2.54z"/>
    <g>
      <path class="st0" d="M2.34,6.74C2.24,7.41,1.1,7.83,0.85,8.86c-0.2,0.85-0.12,1.68,0.9,2.86l0.78-0.51c-0.39-0.53-0.56-1.25-0.45-1.74C2.3,8.58,3.54,8.69,3.88,7.49c0.38-1.31-0.7-2.82-1.44-3.37L1.72,4.71C2.36,5.45,2.45,6.07,2.34,6.74z"/>
    </g>
    <g>
      <path class="st9" d="M4.73,7.66L4.19,7.17c0.3-0.36,0.46-0.82,0.39-1.25c-0.12-0.76-0.63-0.68-0.9-1.54C3.46,3.7,4.05,2.62,4.69,2.15l0.54,0.51C4.85,2.98,4.85,3.45,4.97,3.84c0.22,0.7,0.77,0.68,0.9,1.57C5.98,6.15,5.45,7.25,4.73,7.66z"/>
      <polygon class="st9" points="4.78,12.61 6.14,16.6 4.83,17.01 3.69,12.96"/>
    </g>
    <path class="st0" d="M17.26,14.2c-0.18,1.17-1.01,2.5-1.69,3.46L5.5,7.13c1.17-0.92,2.7-1.49,4.59-1.49c2.45,0,4.26,0.98,5.44,2.41l1.2-0.53c-1.38-1.85-3.58-3.14-6.63-3.14c-2.28,0-3.74,0.53-5.18,1.59C5.21,6.55,5.1,7.68,4.77,8.09l9.89,10.59c-1.14,1.04-2.43,1.49-4.41,1.49c-2.37,0-4.3-0.69-5.54-1.89l-1.19,0.59c1.45,1.64,3.74,2.56,6.77,2.56c5.02,0,7.87-3.61,8.26-7.51L17.26,14.2z"/>
  </svg>

</template>

<script>
export default {
  name: 'IconNoSmokingAreas'
}
</script>

<style scoped>
  .st0{fill-rule:evenodd;clip-rule:evenodd;}
  .st4{display:none;}
  .st9{display:none;fill-rule:evenodd;clip-rule:evenodd;}
  .st10{display:inline;fill-rule:evenodd;clip-rule:evenodd;}
</style>
